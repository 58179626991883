import React from 'react';
import { Route, Redirect, NavLink, useLocation } from "react-router-dom";
// import ReactDOM from 'react-dom';
// import { NavLink, useHistory, Redirect, Route } from 'react-router-dom';

import Header from '../../components/Header/Header.js';
import CustomerManagement from '../CustomerManagement /CustomerManagement.js';
import KYCAndOnboarding from '../KYCAndOnboarding/KYCAndOnboarding.js';
import VirtualAccounts from '../VirtualAccounts/VirtualAccounts.js';

// import AMLAndCompliance from '../AMLAndCompliance/AMLAndCompliance.js';
// import Notifications from '../Notifications/Notifications.js';
// import BlueButton from '../../components/Shared/BlueButton/BlueButton.js';
// import ArrowForwardSharp from '@material-ui/icons/ArrowForwardSharp.js';

import './Dashboard.scss';

function Dashboard(){
	const location = useLocation()
	const page = location.pathname.split('/')[2]
	return(
		<div className="dashboard">
			<Header />
			<Route path="/dashboard">
				<Redirect to="/dashboard/kyc-and-onboarding" />
				{page === 'kyc-and-onboarding' ||
				 page === 'virtual-accounts' || 
				 page === 'customer-management' ? 
				 	<Redirect to={location.pathname} /> 
				 	: 
				 	<Redirect to="/dashboard/kyc-and-onboarding" />
				}
			</Route>
			<div className="dashboard-content">
				<div className="sidebar">
					<div className="top">
						<ul className="nav-primary">
							<li>
								<NavLink to="/dashboard/kyc-and-onboarding" activeClassName='active'>
									<span>
										<img src="/images/kyc-onboarding-icon.svg" alt="kyc-onboarding" />
									</span>
									KYC & Onboarding
								</NavLink>
							</li>
							<li className="accounts">
								<NavLink to="/dashboard/virtual-accounts" activeClassName='active' 
									// onClick={() => {
									// 	setTimeout(() => {
									// 		history.push("/dashboard/virtual-accounts/customer");
									// 	}, 0)
									// }}
								>
									<span>
										<img src="/images/accounts-icon.svg" alt="accounts" />
									</span>
									Virtual Accounts
								</NavLink>
								{/* <ul className="nav-secondry">
									<li>
										<NavLink to="/dashboard/virtual-accounts/customer" activeClassName='active'>
											<span>
												<img src="/images/customer-icon.svg" alt="customer" />
											</span>
											Customer
										</NavLink>
									</li>
									<li>
										<NavLink to="/dashboard/virtual-accounts/business" activeClassName='active'>
											<span>
												<img src="/images/business-icon.svg" alt="business" />
											</span>
											Business
										</NavLink>
									</li>
								</ul> */}
							</li>
							<li>
								<NavLink to="/dashboard/customer-management" activeClassName='active'>
									<span>
										<img src="/images/customer-management-icon.svg" alt="customer-management" />
									</span>
									Customer Management
								</NavLink>
							</li>
							{/* <li>
								<NavLink to="/dashboard/aml-and-compliance" activeClassName='active'>
									<span>
										<img src="/images/aml-compliance-icon.svg" alt="aml-compliance" />
									</span>
									AML & Compliance
								</NavLink>
							</li> */}
						</ul>
						<div className="coming-soon">
							<div className="arrow-label">
								<label>Coming Soon</label>
							</div>
							<ul>
								<li>Callback Configuration</li>
								<li>Process Refunds</li>
								<li>Requests & Approvals</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="content">
					<Route path="/dashboard/kyc-and-onboarding">
						<KYCAndOnboarding />
					</Route>
					<Route path="/dashboard/virtual-accounts">
						<VirtualAccounts />
					</Route>
					<Route path="/dashboard/customer-management">
						<CustomerManagement />
					</Route>
					{/*<Route path="/dashboard/aml-and-compliance">
						<AMLAndCompliance />
					</Route>
					<Route path="/dashboard/notifications">
						<Notifications />
					</Route>*/}
				</div>
			</div>
	 	</div>
 	)
}

export default Dashboard;
