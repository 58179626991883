let apiEndpointList = {
    "COMPANY_MODULE_PROVIDER" : {
        "endpoint" : "/v2/internal/module_provider",
        "baseUrl"  : process.env.REACT_APP_API_ENDPOINT
    },
    "COMPANY_MODULES" : {
        "endpoint" : "/dashboard/modules",
        "baseUrl"  : process.env.REACT_APP_API_ENDPOINT
    },
    "PLAN_TYPE" : {
        "endpoint" : "/v2/internal/plan_type",
        "baseUrl"  : process.env.REACT_APP_API_ENDPOINT
    },
    "PLAN_PRICING_TYPE" : {
        "endpoint" : "/v2/internal/plan_pricing_type",
        "baseUrl"  : process.env.REACT_APP_API_ENDPOINT
    },
    "COMPANY_DETAIL_DATA" : {
        "endpoint" : "/v2/internal/company",
        "baseUrl"  : process.env.REACT_APP_API_ENDPOINT
    },
    "COMPANY_SECRET_DATA" : {
        "endpoint" : "/v2/internal/company_secret",
        "baseUrl"  : process.env.REACT_APP_API_ENDPOINT
    },
    "COMPANY_MODULE" : {
        "endpoint" : "/v2/internal/company_module",
        "baseUrl"  : process.env.REACT_APP_API_ENDPOINT
    },
    "COMPANY_PROVIDER_SECRET" : {
        "endpoint" : "/v2/internal/company_provider_secret",
        "baseUrl"  : process.env.REACT_APP_API_ENDPOINT
    },
    "COMPANY_API_METER" : {
        "endpoint" : "/v2/internal/company_api_meter",
        "baseUrl"  : process.env.REACT_APP_API_ENDPOINT
    },
    "MASTER_ACCOUNT_SETUP":{
        "endpoint" : "/v2/banking/account/master_setup",
        "baseUrl"  : process.env.REACT_APP_API_ENDPOINT
    }
}

export default apiEndpointList;

