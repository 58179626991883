import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import PrivateRoute from './services/privateRoute.js';
import Login from './pages/Login/Login.js';
import Dashboard from './pages/Dashboard/Dashboard.js';

import './app.scss';

function App() {
  return (
    <div className="app-main">
      <div className="app-content">
        <div id="snackbar"></div>
        <div id="failure-popup"></div>
        <div id="add-customer-components"></div>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to='/dashboard' />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <PrivateRoute path="/dashboard" component={Dashboard} />
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
