import React from "react";

// import Dialog from "@material-ui/core/Dialog";
// import DialogContent from "@material-ui/core/DialogContent";
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
//Announcement
// import { makeStyles } from "@material-ui/core/styles";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";

// import KeyboardArrowDownSharp from "@material-ui/icons/KeyboardArrowDownSharp.js";
// import CloseIcon from "@material-ui/icons/Close";

import "./CustomerManagement.scss";
import AddCustomerSummary from "../../components/AddCustomerSummary/AddCustomerSummary";
import AddCustomer from "../../components/AddCustomer/AddCustomer";
import CustomerConfig from "../../components/CustomerConfig/CustomerConfig";

function CustomerManagement() {
  return (
    <React.Fragment>
      <div className="customer-management">
        <h1 className="heading">Customer Management</h1>
        <div className="head-right-block">
          {/* <Edit/> */}
          {/* <AddCustomer/> */}
          <CustomerConfig/>
          <AddCustomer/>
        </div>
      </div>
      <AddCustomerSummary/>
    </React.Fragment>
  );
}

// function ModuleProvider() {
//   const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };
//   return (
//     <div>
//       <div className="addmodule-btn" onClick={handleClickOpen}>
//         <span>Add Client</span>
//       </div>
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="form-dialog-title"
//       >
//         <div className="meterdetails-title">
//           <p className="meterdetails-head">Add New Client</p>
//           <p className="meterdetails-subtitle">Add provider details below</p>
//         </div>
//         <DialogContent>
//           <div className="provider-container">
//               <div className="module-head">
//                 <p>KYC & Onboarding</p>

//               </div>
//           </div>
//         </DialogContent>
//       </Dialog>
//     </div>
//   );
// }

// function Announcement() {
//   const [openAnnouncementModal, setOpenAnnouncementModal] =
//     React.useState(false);

//   const openAnnouncement = () => {
//     setOpenAnnouncementModal(true);
//   };

//   const closeAnnouncement = () => {
//     setOpenAnnouncementModal(false);
//   };

//   const announcementSubmitDetail = (e) => {
//     e.preventDefault();
//   };

//   const useStyles = makeStyles((theme) => ({
//     formControl: {
//       margin: theme.spacing(1),
//       minWidth: 120,
//       "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
//         borderColor: "#0092ff",
//       },
//     },
//     selectEmpty: {
//       marginTop: theme.spacing(1),
//     },
//     optionsControl: {
//       width: 400,
//       height: 40,
//     },
//   }));

//   const classes = useStyles();
//   const [state, setState] = React.useState({
//     age: "",
//     name: "hai",
//   });

//   const handleChange = (event) => {
//     const name = event.target.name;
//     setState({
//       ...state,
//       [name]: event.target.value,
//     });
//   };

//   // File Selection

//   const [selectedFile, setSelectedFile] = React.useState();
//   const [isSelected, setIsSelected] = React.useState("");

//   const changeHandler = (event) => {
//     setSelectedFile(event.target.files[0]);
//     setIsSelected(true);
//   };

//   const removeFile = () => {
//     setSelectedFile("");
//     setIsSelected("");
//   };
//   console.log(selectedFile);

//   return (
//     <div className="announcement">
//       <img onClick={openAnnouncement} src="/images/Announcement.svg" alt="" />

//       <Dialog
//         className="announcement-dialog"
//         open={openAnnouncementModal}
//         aria-labelledby="form-dialog-title"
//       >
//         <div className="announcement-title">
//           <p className="announcement-head">Announcement</p>
//           <p className="announcement-subtitle">
//             Send updates to your customers
//           </p>
//         </div>

//         {/* Close btn */}
//         <div className="close-popup">
//           <CloseIcon onClick={closeAnnouncement} />
//         </div>

//         <DialogContent>
//           <form
//             onSubmit={announcementSubmitDetail}
//             autoComplete="off"
//             spellCheck="false"
//           >
//             <div className="container">
//               <FormControl variant="outlined" className={classes.formControl}>
//                 <Select
//                   native
//                   value={state.age}
//                   className={classes.optionsControl}
//                   onChange={handleChange}
//                   inputProps={{
//                     name: "age",
//                     id: "outlined-age-native-simple",
//                   }}
//                 >
//                   <option aria-label="None" value="" />
//                   <option value={10}>Ten</option>
//                   <option value={20}>Twenty</option>
//                   <option value={30}>Thirty</option>
//                 </Select>
//               </FormControl>
//             </div>

//             <FormControl variant="outlined" className={classes.formControl}>
//               <Select
//                 native
//                 value={state.age}
//                 className={classes.optionsControl}
//                 onChange={handleChange}
//                 inputProps={{
//                   name: "age",
//                   id: "outlined-age-native-simple",
//                 }}
//               >
//                 <option aria-label="None" value="" />
//                 <option value={10}>Ten</option>
//                 <option value={20}>Twenty</option>
//                 <option value={30}>Thirty</option>
//               </Select>
//             </FormControl>

//             <div className="message">
//               <fieldset>
//                 <textarea
//                   placeholder="Type your message here...."
//                   tabindex="5"
//                   required
//                 ></textarea>
//               </fieldset>
//             </div>

//             <div className="select-file">
//               {isSelected ? (
//                 <div className="file-detail">
//                   <p>
//                     <img
//                       onClick={removeFile}
//                       src="/images/delete-icon.svg"
//                       alt=""
//                     />
//                     {selectedFile.name}
//                   </p>
//                 </div>
//               ) : (
//                 ""
//               )}
//               <label class="custom-file-upload">
//                 <input type="file" onChange={changeHandler} />
//                 <img src="/images/attachments-icon.svg" alt="" />
//                 Upload your attachments
//               </label>
//             </div>

//             <div className="announce-btn">
//               <span>Announce</span>
//             </div>
//           </form>
//         </DialogContent>
//       </Dialog>
//     </div>
//   );
// }

// Edit Customer details

// function Edit() {
//   const [openAddcustomerModal, setOpenAddCustomerModal] = React.useState(false);
//   const [pwd, setPwd] = React.useState('');
//   const [isRevealPwd, setIsRevealPwd] = React.useState(false);

//   const openAddCustomer = () => {
//     setOpenAddCustomerModal(true);
//   };

//   const closeAddCustomer = () => {
//     setOpenAddCustomerModal(false);
//   };

//   return (
//     <div className="editcustomer">

//       <div onClick={openAddCustomer} className="edit-btn">
//       <img  src="/images/edit-icon.svg" alt="" />
//       <p>Edit</p>
//       </div>

//       <Dialog
//         className="editcustomer-dialog"
//         open={openAddcustomerModal}
//         aria-labelledby="form-dialog-title"
//       >
//         <div className="editcustomer-title">
//           <p className="editcustomer-head">Edit Customer</p>
//           <p className="editcustomer-subtitle">
//             Edit customer details below
//           </p>
//         </div>

//         {/* Close btn */}
//         <div className="close-popup">
//           <CloseIcon onClick={closeAddCustomer} />
//         </div>

//         <DialogContent>
//           <div className="editcustomer-content">
//             <div className="left-block">
//               <div className="container">
//                 <input autoComplete="off"  type="text" placeholder="Username" />
//               </div>
//               <div className="container">
//                 <input autoComplete="off" type="email" placeholder="Admin Email" />
//               </div>
//               <div className="container">
//                 <input autoComplete="off" type="text" placeholder="Website" />
//               </div>
//             </div>
//             <div className="right-block">
//               <div className="container">
//                 <input autoComplete="off" type="text" placeholder="Company Name" />
//               </div>
//               <div className="container">
//                 <input autoComplete="off" type="tel" placeholder="Contact" />
//               </div>
//               <div className="container-division">
//                 <div className="password">
//                 <input
//                   autoComplete="new-password"
//                   autoCorrect="off"
//                   className="password"
//                   type={isRevealPwd ? "text" : "password"}
//                   value={pwd}
//                   onChange={e => setPwd(e.target.value)}
//                   placeholder="Password"
//                   />
//                   <div  onClick={() => setIsRevealPwd(prevState => !prevState)}  className="password-icon">
//                   {isRevealPwd ?<VisibilityIcon/>:<VisibilityOffIcon/>}
//                   </div>
//                 </div>
//                 <input autoComplete="off" type="text" placeholder="Code" />
//               </div>
//             </div>
//           </div>

//           <div className="modules-container">
//             <p className="head-title">Select Modules</p>
//             <div className="module-content">
//               <div className="content-left">
//                 <div className="checkbox-container">
//                   <input type="checkbox" id="box-1" />
//                   <label htmlFor="box-1">KYC & Onboarding</label>
//                 </div>

//                 <div className="checkbox-container">
//                   <input type="checkbox" id="box-2" />
//                   <label htmlFor="box-2">AML & Compliance</label>
//                 </div>

//                 <div className="checkbox-container">
//                   <input type="checkbox" id="box-3" />
//                   <label htmlFor="box-3">Accounts</label>
//                 </div>
//               </div>
//               <div className="content-right">
//                 <div className="checkbox-container">
//                   <input type="checkbox" id="box-4" />
//                   <label htmlFor="box-4">Lending</label>
//                 </div>

//                 <div className="checkbox-container">
//                   <input type="checkbox" id="box-5" />
//                   <label htmlFor="box-5">Prepaid Cards</label>
//                 </div>

//                 <div className="checkbox-container">
//                   <input type="checkbox" id="box-6" />
//                   <label htmlFor="box-6">Payments</label>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="editcustomer-btn">
//             <span>Add</span>
//           </div>
//         </DialogContent>
//       </Dialog>
//     </div>
//   );
// }

export default CustomerManagement;
