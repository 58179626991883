import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

import './CreditsUtilisedGraph.scss';

function CreditsUtilisedGraph(props){
	props.data.usable_credits = Math.round(props.data.usable_credits)
	props.data.used_credits = Math.round(props.data.used_credits)
	let creditsRemaining = props.data.usable_credits - props.data.used_credits
	let percentageUtilised = ((props.data.used_credits * 100)/props.data.usable_credits).toFixed(2)
	const data = [
		{ name: 'Credits Remaining', value: creditsRemaining < 0? 0 : creditsRemaining},
		{ name: 'Used Credits', value: props.data.used_credits }
	]
	const COLORS = ['#CBCBCB', '#A3A0FB']
	return(
		<React.Fragment>
			{/* <button className="upgrade-button">Upgrade</button> */}
			<div className="credits-utilised-graph-block">
				<div className="chart-section">
					<div style={{ width: 200, height: 200 }}>
		        		<ResponsiveContainer>
							<PieChart>
								<text x={100} y={100} dy={0} textAnchor="middle">{percentageUtilised > 100? 100: percentageUtilised}%</text>
								<text x={99} y={120} dy={0} textAnchor="middle">Credits</text>
								<text x={100} y={135} dy={0} textAnchor="middle">Utilised</text>
						        <Pie data={data} cx={95} cy={100} innerRadius={65} outerRadius={95}
						         fill="#8884d8" paddingAngle={0} dataKey="value">
						          {
						            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
						          }
						        </Pie>
						    </PieChart>
						</ResponsiveContainer>
					</div>
				    <div className="chart-labels">
				    	<div className="legend">
				    		<div className="color-dot" style={{background: '#A3A0FB'}}></div>
				    		<div className="text">
				    			<h2>{props.data.used_credits}</h2>
				    			<label>Credits Utilised</label>
				    		</div>
				    	</div>
				    	<div className="legend">
				    		<div className="color-dot" style={{background: '#CBCBCB'}}></div>
				    		<div className="text">
				    			<h2>{creditsRemaining < 0? 0 : creditsRemaining}</h2>
				    			<label>Credits Remaining</label>
				    		</div>
				    	</div>
				    </div>
				</div>
			</div>
		</React.Fragment>
	);
}

CreditsUtilisedGraph.propTypes = {
	data: PropTypes.any
};

CreditsUtilisedGraph.defaultProps = {
	data: {used_credits: 0, usable_credits: 0}
};

export default CreditsUtilisedGraph;
