import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { getAccessToken } from "axios-jwt";
import { APIConfig } from "../../services/apiConfiguration.js";
import AddCustomerApiMeter from "../AddCustomerApiMeter/AddCustomerApiMeter.js";
import AddCustomerDetails from "../AddCustomerDetails/AddCustomerDetails.js";
import AddCustomerModules from "../AddCustomerModules/AddCustomerModules.js";
import AddCustomerProviderSecret from "../AddCustomerProviderSecret/AddCustomerProviderSecret.js";
import AddCustomerSecretDetail from "../AddCustomerSecretDetail/AddCustomerSecretDetail.js";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import { Loader } from "rsuite";
import APIEndpointList from "../../config/modules/customer_management/endpoint";

import "./AddCustomer.scss";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

function AddCustomer() {
  let commonCompanyId;
  let companyInputData;
  let companySecretInputData;
  let modResponse;
  let moduleProviderResponse;
  let planOptions;
  let planPricing;

  const providerNamesApi = () => {
    APIConfig.API_Client.get(
      APIEndpointList.COMPANY_MODULE_PROVIDER.baseUrl +
        APIEndpointList.COMPANY_MODULE_PROVIDER.endpoint,
      { cancelToken: source.token }
    )
      .then((response) => {
        moduleProviderResponse = response.data.data;
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  const modulesData = () => {
    APIConfig.API_Client.get(
      APIEndpointList.COMPANY_MODULES.baseUrl +
        APIEndpointList.COMPANY_MODULES.endpoint,
      { cancelToken: source.token }
    )
      .then((response) => {
        modResponse = response.data.result.filter((module) => {
          return module.code !== "ledger";
        });
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  React.useEffect(() => {
    modulesData();
    providerNamesApi();
  });

  const closeModal = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("add-customer-components")
    );
  };

  const planTypes = () => {
    APIConfig.API_Client.get(
      APIEndpointList.PLAN_TYPE.baseUrl + APIEndpointList.PLAN_TYPE.endpoint,
      {
        headers: {
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        // planOptions = response.data.data;
        planOptions = response.data.data.filter((plan) => {
          return plan === "PREPAID_CREDITS";
        });
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  const planPricingType = () => {
    APIConfig.API_Client.get(
      APIEndpointList.PLAN_PRICING_TYPE.baseUrl +
        APIEndpointList.PLAN_PRICING_TYPE.endpoint,
      {
        headers: {
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        // planPricing = response.data.data;
        planPricing = response.data.data.filter((plan) => {
          return plan === "STARTER";
        });
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  const openCustomerDetail = () => {
    ReactDOM.render(
      <AddCustomerDetails
        closeModal={closeModal}
        addCompanyData={addCompanyData}
      />,
      document.getElementById("add-customer-components")
    );

    planTypes();
    planPricingType();
  };

  const addCompanyData = (companyDetailData, companySecretData) => {
    companyInputData = companyDetailData;
    companySecretInputData = companySecretData;

    ReactDOM.render(<Loader />, document.getElementById("addCustomer-loader"));
    companyDetailApi();
  };

  const companyDetailApi = () => {
    APIConfig.API_Client.post(
      APIEndpointList.COMPANY_DETAIL_DATA.baseUrl +
        APIEndpointList.COMPANY_DETAIL_DATA.endpoint,
      companyInputData
    )
      .then((response) => {
        commonCompanyId = response.data.data.companyId;
        companySecretInputData.company_id = response.data.data.companyId;
        companySecretApi();
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("addCustomer-loader")
        );

        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  const companySecretApi = () => {
    APIConfig.API_Client.post(
      APIEndpointList.COMPANY_SECRET_DATA.baseUrl +
        APIEndpointList.COMPANY_SECRET_DATA.endpoint,
      companySecretInputData
    )
      .then((response) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("addCustomer-loader")
        );

        ReactDOM.unmountComponentAtNode(
          document.getElementById("add-customer-components")
        );

        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );

        ReactDOM.render(
          <AddCustomerModules
            addCompanyModules={addCompanyModules}
            modResponse={modResponse}
            moduleProviderResponse={moduleProviderResponse}
          />,
          document.getElementById("add-customer-components")
        );
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("addCustomer-loader")
        );

        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  let selectedModuleData;
  let providerData;
  let apiMeterData;

  const addCompanyModules = (modulesData, providerObj, apiMeterObj) => {
    selectedModuleData = modulesData;
    providerData = providerObj;
    apiMeterData = apiMeterObj;
    ReactDOM.render(
      <Loader />,
      document.getElementById("addCustomer-module-loader")
    );
    companyModuleApi();
  };

  const companyModuleApi = () => {
    APIConfig.API_Client.post(
      APIEndpointList.COMPANY_MODULE.baseUrl +
        APIEndpointList.COMPANY_MODULE.endpoint,
      { company_id: commonCompanyId, module_details: selectedModuleData }
    )
      .then((response) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("addCustomer-module-loader")
        );

        ReactDOM.unmountComponentAtNode(
          document.getElementById("add-customer-components")
        );

        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
        if (providerData.length === 0) {
          ReactDOM.render(
            <AddCustomerApiMeter
              planOptions={planOptions}
              planPricing={planPricing}
              addApiMeterDetails={addApiMeterDetails}
              apiMeterData={apiMeterData}
            />,
            document.getElementById("add-customer-components")
          );
        } else {
          ReactDOM.render(
            <AddCustomerProviderSecret
              addProviderSecret={addProviderSecret}
              providerData={providerData}
            />,
            document.getElementById("add-customer-components")
          );
        }
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("addCustomer-module-loader")
        );

        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  let providerSecretData;
  let successSecretStateData;

  const addProviderSecret = (providerSecretDetail, secretData) => {
    providerSecretData = providerSecretDetail;
    successSecretStateData = secretData;
    ReactDOM.render(
      <Loader />,
      document.getElementById("provider-customer-loader")
    );

    providerSecretApi();
  };

  const providerSecretApi = () => {
    APIConfig.API_Client.post(
      APIEndpointList.COMPANY_PROVIDER_SECRET.baseUrl +
        APIEndpointList.COMPANY_PROVIDER_SECRET.endpoint,
      { company_id: commonCompanyId, provider_details: providerSecretData }
    )
      .then((response) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("provider-customer-loader")
        );

        ReactDOM.unmountComponentAtNode(
          document.getElementById("add-customer-components")
        );

        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
        ReactDOM.render(
          <AddCustomerApiMeter
            planOptions={planOptions}
            planPricing={planPricing}
            addApiMeterDetails={addApiMeterDetails}
            apiMeterData={apiMeterData}
          />,
          document.getElementById("add-customer-components")
        );
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("provider-customer-loader")
        );

        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  let apiMeterPayloadData;

  const addApiMeterDetails = (ApiMeterDetailsDetail) => {
    apiMeterPayloadData = ApiMeterDetailsDetail;

    ReactDOM.render(
      <Loader />,
      document.getElementById("apiMeter-customer-loader")
    );

    meterDetailsApi();
  };

  const meterDetailsApi = () => {
    APIConfig.API_Client.post(
      APIEndpointList.COMPANY_API_METER.baseUrl +
        APIEndpointList.COMPANY_API_METER.endpoint,
      { company_id: commonCompanyId, api_meter_details: apiMeterPayloadData }
    )
      .then((response) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("apiMeter-customer-loader")
        );

        ReactDOM.unmountComponentAtNode(
          document.getElementById("add-customer-components")
        );

        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );

        ReactDOM.render(
          <AddCustomerSecretDetail
            companySecretInputData={companySecretInputData}
            selectedModuleData={selectedModuleData}
            successSecretStateData={successSecretStateData}
          />,
          document.getElementById("add-customer-components")
        );
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("apiMeter-customer-loader")
        );

        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  return (
    <div>
      <div onClick={openCustomerDetail} className="add-client_btn">
        <span>
          <img src="/images/add-icon.svg" alt="add-sign" />
          Add Customer
        </span>
      </div>
    </div>
  );
}

export default AddCustomer;
