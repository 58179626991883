import React from "react";
import ReactDOM from "react-dom";

import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";

import SnackbarMessage from "../Shared/SnackbarMessage/SnackbarMessage";

import "./CustomerConfigSuccessDialog.scss";

let successResponseData;

function CustomerConfigSuccessDialog(props) {
  const closeSuccessDialog = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById("add-customer-components"));
  };

  function copyResponseDetail() {
    const elTable = document.querySelector(".configure-account-data");
    let range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
          document.getElementById("snackbar")
        );
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand("copy");
    }
    sel.removeAllRanges();
  }

  successResponseData = props.data;
  return (
    <React.Fragment>
      <Dialog
        className="customer-config-success"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-success-popup">
          <CloseIcon onClick={closeSuccessDialog} />
        </div>

        <DialogContent>
          <div className="dialog-heading">
            <p>Account Config</p>
          </div>
          <div className="dialog-content-container">
            <div className="success-message">
              <img src="/images/onsuccess-tick.svg" alt="success-icon" />
              <h3>Account setup successful!</h3>
              <p>{successResponseData.message}</p>
            </div>
            <div className="success-account-details">
              <table className="configure-account-data">
                <tbody>
                  <tr>
                    <td className="detail-heading">Commission Account</td>
                    <td className="detail-value title-case">{props.accountNumber}</td>
                  </tr>
                  <tr>
                    <td className="detail-heading">Commission Debit Account</td>
                    <td className="detail-value">
                      {successResponseData.data.commissionDebitAccountNumber}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">Commission Credit Account</td>
                    <td className="detail-value">
                      {successResponseData.data.commissionCreditAccountNumber}
                    </td>
                  </tr>
                </tbody>
              </table>
              <button className="copy-account-details" onClick={copyResponseDetail} type="button">
                <img src="/images/copy-icon.svg" alt="copy-icon" />
                Copy
              </button>
            </div>
            <div className="success-btn-container">
              <button onClick={closeSuccessDialog} className="success-btn-done">
                Done
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default CustomerConfigSuccessDialog;
