import React from "react";
import ReactDOM from "react-dom";

import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";

import "./AddCustomerSecretDetail.scss";

function AddCustomerSecretDetail(props) {
  const closeModal = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById("add-customer-components"));
  };

  function copyClip() {
    const elTable = document.querySelector(".table-data");

    let range, sel;

    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
          document.getElementById("snackbar")
        );
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand("copy");
    }

    sel.removeAllRanges();
  }

  return (
    <div>
      <Dialog
        className="customer-secret-dialog"
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="customer-secret-title">
          <p className="customer-secret-head">Credentials</p>
          <p className={"customer-secret-subtitle"}>Review your credentials</p>
        </div>

        <div className="close-popup">
          <CloseIcon onClick={closeModal} />
        </div>

        <DialogContent className="customer-secret-dialog">
          <div className="customer-secrets-details">
            <table className="table-data">
              <tbody>
                <tr>
                  <td className="secrets-heading">client_id</td>
                  <td className="secrets-details">
                    {props.companySecretInputData.client_id}
                  </td>
                </tr>

                <tr>
                  <td className="secrets-heading">client_secret</td>
                  <td className="secrets-details">
                    {props.companySecretInputData.client_secret}
                  </td>
                </tr>

                {props.selectedModuleData &&
                  props.selectedModuleData.map((item, indx) => {
                    return (
                        <tr key={indx}>
                          <td className="secrets-heading">{item.code}</td>
                          <td className="secrets-details">{item.secret}</td>
                        </tr>
                    );
                  })}

                {props.successSecretStateData &&
                  props.successSecretStateData.map((item, indx) => {
                    return (
                        <tr key={indx}>
                          <td className="secrets-heading">
                            {item.moduleProvider}
                          </td>
                          <td className="secrets-details">
                            {item.providerSecret}
                          </td>
                        </tr>
                    );
                  })}
              </tbody>
            </table>
            <button
              className="btn-copy-secret"
              onClick={copyClip}
              id="copy_btn"
              type="button"
            >
              <img src="/images/copy-icon.svg" alt="copy-icon" />
              Copy
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddCustomerSecretDetail;
