import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import Checkbox from '@material-ui/core/Checkbox';

import './APIUsageGraph.scss';

function APIUsageGraph(props){
	let data = []
	let total = 0
	Object.entries(props.data).forEach(function([key,value],i){ 
		data.push({name: key, value: value})
		total = total + value
	})
	const COLORS = ['#1976D2', '#512DA8', '#7B1FA2', '#0097A7', '#FF5722', '#5D4037', '#F57C00', '#607D8B', '#C2185B', '#4CAF50']
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// event.target.checked = !event.target.checked;
	}
	return(
		<React.Fragment>
			<div className="usage-graph-menu">
				<div className="usage-graph-dropdown">
					{/*<span onClick={ToggleConfigureMenu}><img src="/images/gear-option.svg" alt="gear-option" /></span>*/}
					<div className="usage-graph-dropdown-content">
						<form noValidate>
							<ul>
								{
									data.map((entry,i) =>
										<li key={i}>
											<Checkbox
												onChange={handleChange}
												color="default"
											/>
											{entry.name}
										</li>
									)
								}
								{
									data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
								}
							</ul>
							<button className="apply-button">Apply Changes</button>
						</form>
					</div>
				</div>
			</div>
			<div className="api-usage-graph-block">
				<div style={{ width: 200, height: 200 }}>
	        		<ResponsiveContainer>
						<PieChart>
							<text x={100} y={100} dy={0} textAnchor="middle">{total}</text>
							<text x={100} y={120} dy={0} textAnchor="middle">Total</text>
					        <Pie data={data} cx={95} cy={100} innerRadius={65} outerRadius={95}
					         fill="#8884d8" paddingAngle={0} dataKey="value">
					          {
					          	data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
					          }
					        </Pie>
					    </PieChart>
					</ResponsiveContainer>
				</div>
			    <div className="chart-labels">
					{
						data.map((entry,i) =>
							<div className="legend" key={i}>
					    		<div className="color-dot" style={{background: COLORS[i % COLORS.length]}}></div>
					    		<div className="text">
					    			<h2>{entry.value}</h2>
					    			<label>{entry.name}</label>
					    		</div>
					    	</div>
						)
					}
			    </div>
			</div>
		</React.Fragment>
	)
}

// function ToggleConfigureMenu(){
// 	document.querySelector('.usage-graph-dropdown-content').classList.toggle('active');
// }

APIUsageGraph.propTypes = {
	data: PropTypes.any
};

APIUsageGraph.defaultProps = {
	data: {}
};

export default APIUsageGraph;
