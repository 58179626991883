import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import Pagination from '@material-ui/lab/Pagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import KeyboardArrowLeftSharp from '@material-ui/icons/KeyboardArrowLeftSharp.js';
// import SearchOutlined from '@material-ui/icons/SearchOutlined.js';
import { APIConfig } from '../../services/apiConfiguration.js';
import moment from 'moment';

import RandomQuote from '../../components/RandomQuote/RandomQuote.js';
import ResponseCodes from '../ResponseCodes/ResponseCodes.js';

import './APISummaryTable.scss';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	table: {
		minWidth: 750,
	},
	icon: {
	    borderRadius: 3,
	    width: 17,
	    height: 17,
	    backgroundColor: '#FFFFFF',
	    border: '1px solid #E8E9EC',
	    'input:disabled ~ &': {
	      background: 'rgba(206,217,224,.5)',
	    },
  	},
	checkedIcon: {
		backgroundColor: '#137cbd',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 17,
			height: 17,
			backgroundImage:
			"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
			" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
			"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#106ba3',
		},
	},
}));

function APISummaryTable(props){
	const classes = useStyles()
	// const [order, setOrder] = React.useState('asc')
	// const [orderBy, setOrderBy] = React.useState('createdOn')
	// const [selected, setSelected] = React.useState([])
	const [page, setPage] = React.useState(1)
	const rowsPerPage = 7
	let originalRows = []
	let filteredRows = []
	let totalRecords = 0
	let emptyRows = 0
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	const getAPISummaryData = () => {
		APIConfig.API_Client.get(APIConfig.BASE_URL+'/dashboard/admin/api_summary?'+
			((props.companyId)? '&company_id='+props.companyId: '')+
			'&module_name='+props.pageType+
			'&start_date='+
			moment(props.selectedDates[0].toString()).format("YYYY-MM-DD")+
			'&end_date='+
			moment(props.selectedDates[1].toString()).format("YYYY-MM-DD")+
			'&page_no='+
			page,
			{cancelToken: source.token}
		).then(response => {
			ReactDOM.unmountComponentAtNode(document.getElementById('summaryTable'))
			let row = []
			originalRows = []
			filteredRows = []
			emptyRows = 0
			totalRecords = response.data.data.count
			for (let i=0; i < response.data.data.records.length; i++){
				row = response.data.data.records[i]
				originalRows.push(createData(row[0], row[1], row[2], row[3], row[4]))
				filteredRows.push(createData(row[0], row[1], row[2], row[3], row[4]))
			}
		}).then(() => {
			emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredRows.length)
			ReactDOM.render(<RenderTable />, document.getElementById('summaryTable'))
		}).catch(error => {
			console.log(error.message)
		})
	}

	useEffect(() => {
		getAPISummaryData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page])

	useEffect(() => {
        return () => {
        	if (source)
        		source.cancel("API Summary API Cancelled");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	// function handleSearch(event){
	// 	filteredRows = []
	// 	for (let i=0; i < originalRows.length; i++){
	// 		if (originalRows[i]['createdOn'].toString().toLowerCase().includes(event.currentTarget.value.toLowerCase())
	// 			|| originalRows[i]['apiName'].toString().toLowerCase().includes(event.currentTarget.value.toLowerCase())
	// 			|| originalRows[i]['companyUrn'].toString().toLowerCase().includes(event.currentTarget.value.toLowerCase())
	// 			|| originalRows[i]['responseCode'].toString().toLowerCase().includes(event.currentTarget.value.toLowerCase())
	// 			|| originalRows[i]['statusCode'].toString().toLowerCase().includes(event.currentTarget.value.toLowerCase())
	// 		   )
	// 			filteredRows.push(originalRows[i]);
	// 	}
	// 	ReactDOM.unmountComponentAtNode(document.getElementById('summaryTable'));
	// 	ReactDOM.render(<RenderTable />, document.getElementById('summaryTable'));
	// }

	// function handleRequestSort(event, property){
	// 	const isAsc = orderBy === property && order === 'asc';
	// 	setOrder(isAsc ? 'desc' : 'asc');
	// 	setOrderBy(property);
	// }

	// function handleSelectAllClick(event){
	// 	if (event.target.checked) {
	// 		const newSelecteds = filteredRows.map((n) => n.companyUrn);
	// 		setSelected(newSelecteds);
	// 		return;
	// 	}
	// 	setSelected([]);
	// }

	// function handleClick(event, name){
	// 	const selectedIndex = selected.indexOf(name);
	// 	let newSelected = [];
	// 	if (selectedIndex === -1)
	// 		newSelected = newSelected.concat(selected, name);
	// 	else if (selectedIndex === 0)
	// 		newSelected = newSelected.concat(selected.slice(1));
	// 	else if (selectedIndex === selected.length - 1)
	// 		newSelected = newSelected.concat(selected.slice(0, -1));
	// 	else if (selectedIndex > 0) {
	// 		newSelected = newSelected.concat(
	// 			selected.slice(0, selectedIndex),
	// 			selected.slice(selectedIndex + 1),
	// 		);
	// 	}
	// 	setSelected(newSelected);
	// }

	function handleChangePage(event, newPage){
		setPage(newPage);
	}

	// function descendingComparator(a, b, orderBy) {
	// 	if (b[orderBy] < a[orderBy])
	// 		return -1;
	// 	if (b[orderBy] > a[orderBy])
	// 		return 1;
	// 	return 0;
	// }

	// function getComparator(order, orderBy) {
	// 	return order === 'desc'? 
	// 	(a, b) => descendingComparator(a, b, orderBy):(a, b) => -descendingComparator(a, b, orderBy);
	// }

	// function stableSort(array, comparator) {
	// 	const stabilizedThis = array.map((el, index) => [el, index]);
	// 	stabilizedThis.sort((a, b) => {
	// 		const order = comparator(a[0], b[0]);
	// 		if (order !== 0) return order;
	// 		return a[1] - b[1];
	// 	});
	// 	return stabilizedThis.map((el) => el[0]);
	// }

	function createData(createdOn, apiName, companyUrn, responseCode, statusCode) {
		return { createdOn, apiName, companyUrn, responseCode, statusCode }
	}

	// const isSelected = (name) => selected.indexOf(name) !== -1;

	return(
		<React.Fragment>
			<div className="api-summary">
				<div className="head">
					<h2 onClick={() => {
						props.backToGraphScreen()
					}}><KeyboardArrowLeftSharp />Go Back</h2>
					{/*<div className="search-block">
						<div className="search">
							<SearchOutlined />
							<input type="text" placeholder="Search" onChange={handleSearch} />
						</div>
						<img src="/images/filter.svg" alt="filter-icon" />
					</div>*/}
				</div>
				<div id="summaryTable" className="summary-table"></div>
			</div>
			<ResponseCodes />
		</React.Fragment>
	)

	function RenderTable(){
		return(
			<React.Fragment>
				{filteredRows.length > 0? 
					<div className={classes.root}>
						<TableContainer>
							<Table
								className={classes.table}
								aria-labelledby="tableTitle"
								size='medium'
								aria-label="enhanced table"
							>
								<EnhancedTableHead
									classes={classes}
									// numSelected={selected.length}
									// order={order}
									// orderBy={orderBy}
									// onSelectAllClick={handleSelectAllClick}
									// onRequestSort={handleRequestSort}
									rowCount={filteredRows.length}
								/>
								<TableBody>
									{filteredRows.map((row, index) => {
											// const isItemSelected = isSelected(row.companyUrn);
											const labelId = `enhanced-table-checkbox-${index}`;
											return (
												<TableRow
													hover
													// onClick={(event) => handleClick(event, row.companyUrn)}
													role="checkbox"
													// aria-checked={isItemSelected}
													tabIndex={-1}
													key={row.companyUrn}
													// selected={isItemSelected}
												>
													{/*<TableCell padding="checkbox">
														<Checkbox
															disableRipple
															color="default"
															checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
															icon={<span className={classes.icon} />}
															checked={isItemSelected}
															inputProps={{ 'aria-labelledby': labelId }}
														/>
													</TableCell>*/}
													<TableCell component="th" id={labelId} scope="row">
														{moment(row.createdOn).format("Do MMM YYYY, h:mm:ss A")}
													</TableCell>
													<TableCell align="left">{row.apiName}</TableCell>
													<TableCell align="center">{row.companyUrn}</TableCell>
													<TableCell align="center">{row.responseCode}</TableCell>
													<TableCell align="center"><span className={"table-label status-code-"+row.statusCode}>{row.statusCode}</span></TableCell>
												</TableRow>
											);
										})
									}
									{emptyRows > 0 && (
										<TableRow style={{ height: 73 * emptyRows }}>
											<TableCell colSpan={5} />
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						<Pagination 
							page={page} 
							count={(totalRecords%7) === 0?(totalRecords/7):parseInt((totalRecords/7) + 1)} 
							onChange={handleChangePage} 
							defaultPage={1} />
					</div>
					:
					<RandomQuote />
				}
			</React.Fragment>
		)
	}

}

APISummaryTable.propTypes = {
	classes: PropTypes.object,
	// numSelected: PropTypes.number,
	// onRequestSort: PropTypes.func,
	// onSelectAllClick: PropTypes.func,
	// order: PropTypes.oneOf(['asc', 'desc']),
	// orderBy: PropTypes.string,
	rowCount: PropTypes.number,
};

// Table Head
const headCells = [
	{ id: 'createdOn', alignment: 'left', disablePadding: false, label: 'Created On' },
	{ id: 'apiName', alignment: 'left', disablePadding: false, label: 'API Name' },
	{ id: 'companyUrn', alignment: 'center', disablePadding: false, label: 'Company URN' },
	{ id: 'responseCode', alignment: 'center', disablePadding: false, label: 'Response Code' },
	{ id: 'statusCode', alignment: 'center', disablePadding: false, label: 'Status Code' },
];
function EnhancedTableHead(props) {
	// const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	// const { order, orderBy, onRequestSort } = props;
	// const createSortHandler = (property) => (event) => {
	// 	onRequestSort(event, property);
	// }
	return (
		<TableHead>
			<TableRow>
				{/*<TableCell padding="checkbox">
					<Checkbox
						disableRipple
						color="default"
						checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
						icon={<span className={classes.icon} />}
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'Select All APIs' }}
					/>
				</TableCell>*/}
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.alignment}
						padding={headCell.disablePadding ? 'none' : 'default'}
						// sortDirection={orderBy === headCell.id ? order : false}
					>
						{/*<TableLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
						</TableLabel>*/}
						{headCell.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	// numSelected: PropTypes.number.isRequired,
	// onRequestSort: PropTypes.func.isRequired,
	// onSelectAllClick: PropTypes.func.isRequired,
	// order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	// orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
}

export default APISummaryTable;