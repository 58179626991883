import React, { useEffect } from 'react';
import axios from 'axios';

import { APIConfig } from '../../services/apiConfiguration.js';

import './ResponseCodes.scss';

function ResponseCodes() {

	const [responseCodes, setResponseCodes] = React.useState([])
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	const getResponseCodes = () => {
		APIConfig.API_Client.get(
			APIConfig.BASE_URL+'/dashboard/response_codes',
			{cancelToken: source.token}
		).then(response => {
			setResponseCodes(response.data.data)
		}).catch(error => {
			console.log(error.message)
		})
	}

	useEffect(() => {
		getResponseCodes()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
        return () => {
        	if (source)
        		source.cancel("Get Response Code API Cancelled");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	return(
		<div className="response-codes-section">
			<div className="response-codes-description">
				<h2>Response Codes Reference</h2>
				<ul>
					{
						responseCodes.map((items, index) => {
							return(
								Object.keys(items).map((key) => {
									return(
										<li className="code-description" key={index}>
											<label>{key}&nbsp;:</label><span>{items[key]}</span>
										</li>
									)
								})
							)
						})
					}
				</ul>
			</div>
		</div>
	)

}

export default ResponseCodes;
