import React from 'react';
import ReactDOM from 'react-dom';
import { setAuthTokens } from "axios-jwt";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as forge from 'node-forge';
import { APIConfig,AuthResponseToAuthTokens } from '../../services/apiConfiguration.js';
import SnackbarMessage from '../../components/SnackbarMessage/SnackbarMessage.js';

import './Login.scss';

function Login(){
	let history = useHistory();
	const userData = [{
		role: "admin",
		email: undefined,
		password: undefined
	}]
	const errors = {
		role: '',
		email: '',
		password: ''
	}
	return(
		<div className="login">
			<div className="login-section">
				<div className="form-section">
					<img src="./images/logo.svg" alt="logo" />
					<h1>Welcome back! Please login to your account.</h1>
					<div className="input-section">
						<form onSubmit={handleSubmit} autoComplete="off">
							<div className="inputs">
								<select className="type1" defaultValue="admin" name="role" onChange={handleChange}>
									<option value="admin">Admin</option>
									<option value="operations">Operations</option>
								</select>
								<span className="error" id="role_error"></span>
								<input className="type1" type="email" name="email" placeholder="Enter your email"
									onChange={handleChange} required
								/>
								<span className="error" id="email_error"></span>
								<input className="type1" type="password" name="password" placeholder="Password"
									onChange={handleChange} required
								/>
								<span className="error" id="password_error"></span>
							</div>
							{/*<div className="login-options">
								<div className="remember-me">
									<input type="checkbox" />
									<label>Remember me</label>
								</div>
								<Link to="/forgot-password">Forgot Password?</Link>
							</div>*/}
							<button className="login-button" type="submit">Login</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
	function handleChange(event){
		userData[0][event.currentTarget.name] = event.currentTarget.value
	}
	function validateInputs(){
		let valid = true;
		if(userData[0]['role'] === undefined){
			errors.role = "Role is required";
			ReactDOM.render(errors.role, document.getElementById('role_error'));
			valid = false;
		}
		else{
			errors.role = undefined;
			ReactDOM.unmountComponentAtNode(document.getElementById('role_error'));
		}
		if(userData[0]['email'] === undefined){
			errors.email = "Email is required";
			ReactDOM.render(errors.email, document.getElementById('email_error'));
			valid = false;
		}
		else if(!userData[0]['email'].match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)){
			errors.email = "Please enter valid email";
			ReactDOM.render(errors.email, document.getElementById('email_error'));
			valid = false;
		}
		else{
			errors.email = undefined;
			ReactDOM.unmountComponentAtNode(document.getElementById('email_error'));
		}
		if(userData[0]['password'] === undefined){
			errors.password = "Password is required";
			ReactDOM.render(errors.password, document.getElementById('password_error'));
			valid = false;
		}
		else{
			errors.password = undefined;
			ReactDOM.unmountComponentAtNode(document.getElementById('password_error'));
		}
		return valid;
	}
	function handleSubmit(event){
		event.preventDefault();
		const publicKey = process.env.REACT_APP_AUTH_ENCRYPTION_PUBLIC_KEY
		const forgedPublicKey = forge.pki.publicKeyFromPem(publicKey)
		let encryptedPassword = forgedPublicKey.encrypt(userData[0]["password"], "RSA-OAEP", {
			md: forge.md.sha256.create(),
			mgf1: forge.mgf1.create()
		})
		const finalEncryptedPassword = forge.util.encode64(encryptedPassword)
		if(validateInputs()){
			axios.post(APIConfig.BASE_URL+'/user/internal/auth', {'email': userData[0]["email"], 'password': finalEncryptedPassword})
			.then(
			response => {
				if (response.status === 200){
					if (response.data.message !== undefined && 
						response.data.access_token === undefined){
						ReactDOM.render(<SnackbarMessage msgtype="error" msg={response.data.message} />, document.getElementById('snackbar'));
					}
					else if(!response.data.email.includes('@decentro.tech')){
						ReactDOM.render(<SnackbarMessage msgtype="error" msg="Unauthorized user" />, document.getElementById('snackbar'));
					}
					else{
						setAuthTokens(AuthResponseToAuthTokens(response.data))
						localStorage.setItem("user", JSON.stringify({'name': response.data.name, 'email': response.data.email , "client_id": response.data.client_id}))
						history.push("/dashboard");
					}
				}
			},
			error => {
				ReactDOM.render(<SnackbarMessage msgtype="error" msg="Something went wrong" />, document.getElementById('snackbar'));
			})
		}
	}
}

export default Login;
