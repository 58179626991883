import React from "react";
import ReactDOM from "react-dom";
import { randomUUID } from "../../services/randomUUID.js";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import moduleWithoutProviderList from '../../config/moduleWithoutProviderList/moduleWithoutProviderList';

import "./AddCustomerModules.scss";

function AddCustomerModules(props) {
  const mergeByModuleCode = (a1, a2) =>
    a1.map((itm) => ({
      ...a2.find((item) => item.moduleCode === itm.code && item),
      ...itm,
      moduleSecret: randomUUID(),
    }));

  const mappedData = mergeByModuleCode(props.modResponse, props.moduleProviderResponse);


  const initialData = mappedData.map((item, idx) => {
    return {
      ...item,
      moduleCheckbox: false,
      moduleCode: item.code,
      moduleProvider: "",
      moduleProviderId: "",
    };
  });

  const [modulesInputData, setModulesInputData] = React.useState(initialData);
  const [btnVerify, setBtnVerify] = React.useState(1);
  const [btnValidationCheck, setBtnValidationCheck] = React.useState([]);


  React.useEffect(() => {
    const checkResult = modulesInputData.filter((item, idx) => {
      return item.moduleCheckbox === true;
    });

    setBtnValidationCheck(checkResult);
  }, [modulesInputData]);

  React.useEffect(() => {

    if (btnValidationCheck.length > 0) {
      let count = 0;
      for (let i = 0; i < btnValidationCheck.length; i++) {
        const element = btnValidationCheck[i];
        if (
          ((moduleWithoutProviderList.includes(element.moduleCode)) || ((!moduleWithoutProviderList.includes(element.moduleCode)) && element.moduleProvider !== ""))
        ) {
          count ++;
        }
      }
      if (count === btnValidationCheck.length) {
          setBtnVerify(0);
        }
      else {
        setBtnVerify(1);
      }
  } else {
    setBtnVerify(1);
  }
  }, [btnValidationCheck]);


  const modulesPayloadData = modulesInputData
    .filter((item) => {
      if (item.moduleCheckbox === true && !!item.moduleSecret) return item;
      return false;
    })
    .map((el, index) => {
      return {
        code: el.moduleCode,
        secret: el.moduleSecret,
      };
    });



  const providerObj = modulesInputData
    .filter((item) => {
      if (item.moduleCheckbox === true && !!item.moduleProvider) return item;
      return false;
    })
    .map((el, index) => {
      return {
        moduleProviderId: el.moduleProviderId,
        moduleProvider: el.moduleProvider,
      };
    });


  const apiMeterObj = modulesInputData
    .filter((item) => {
      if (item.moduleCheckbox === true && !!item.moduleSecret) return item;
      return false;
    })
    .map((el, index) => {
      return {
        moduleCode: el.code,
        moduleName: el.name,
      };
    });

  const openProviderSecretDialog = () => {
    props.addCompanyModules(modulesPayloadData, providerObj, apiMeterObj);
  };

  const closeModal = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("add-customer-components")
    );
  };

  return (
    <React.Fragment>
      <Dialog
        className="addCustomer-module-dialog"
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="addCustomer-module-title">
          <p className="addCustomer-module-head">Select Modules</p>
          <p className="addCustomer-module-subtitle">
            Select modules and providers
          </p>
        </div>

        <div className="close-popup">
          <CloseIcon onClick={closeModal} />
        </div>

        <DialogContent>
          <div className="client-module-container">
            {modulesInputData.map((data, indx) => {
              return (
                <div key={indx}>
                  {data.is_active && (
                    <div className="module-container">
                      <div className="checkbox-container">
                        <input
                          type="checkbox"
                          disabled={
                            data.code === "payments" ||
                            data.code === "ppi_wallet"
                              ? true
                              : false
                          }
                          id={data.code}
                          autoComplete="off"
                          onChange={(e) => {
                            data.moduleCheckbox = e.target.checked;
                            const indexOfCbObject = modulesInputData.findIndex(
                              (i) => i.code === "core_banking"
                            );

                            if (modulesInputData[indexOfCbObject].moduleCheckbox === true) {
                              if (document.getElementById("payments")) {
                                document.getElementById("payments").disabled = false;
                              }
                              if (document.getElementById("ppi_wallet")) {
                                document.getElementById("ppi_wallet").disabled = false;
                              }
                            } else {
                              if (document.getElementById("payments")) {
                                document.getElementById("payments").disabled = true;
                                let indexOfPaymentObject = modulesInputData.findIndex(
                                    (i) => i.code === "payments"
                                  );
                                modulesInputData[indexOfPaymentObject].moduleCheckbox = false;
                                document.getElementById("payments").checked = false;
                              }
                              if (document.getElementById("ppi_wallet")) {
                                document.getElementById("ppi_wallet").disabled = true;
                                let indexOfPpiObject =
                                  modulesInputData.findIndex(
                                    (i) => i.code === "ppi_wallet"
                                  );
                                indexOfPpiObject = modulesInputData.findIndex(
                                  (i) => i.code === "ppi_wallet"
                                );
                                modulesInputData[indexOfPpiObject].moduleCheckbox = false;
                                document.getElementById("ppi_wallet").checked = false;
                              }
                            }
                            if (data.moduleCheckbox === false) {
                              data.moduleProviderId = "";
                              data.moduleProvider = "";
                            }
                            setModulesInputData([...modulesInputData]);
                          }}
                          value={data.checked}
                        />
                        <label htmlFor={data.code}>{data.name}</label>
                      </div>

                      {data.moduleCheckbox && (
                        <>
                          <div className="form-field_select">
                            <div className="select">
                              <select
                                disabled={
                                  data.code === "kyc_and_onboarding" ||
                                  data.code === "aml_and_compliance"
                                    ? true
                                    : false
                                }
                                onChange={({ target }) => {
                                  data.moduleProviderId = target.value;
                                  data.moduleProvider =
                                    target[target.selectedIndex].text;

                                  const indexOfCbProvider =
                                    modulesInputData.findIndex(
                                      (i) => i.code === "core_banking"
                                    );
                                  const indexOfPayProvider =
                                    modulesInputData.findIndex(
                                      (i) => i.code === "payments"
                                    );

                                  if (!!modulesInputData[indexOfCbProvider].moduleProvider) {
                                    modulesInputData[indexOfPayProvider].moduleProvider =modulesInputData[indexOfCbProvider].moduleProvider;
                                    modulesInputData[indexOfPayProvider].moduleProviderId = modulesInputData[indexOfCbProvider].moduleProviderId;
                                  } else {
                                    modulesInputData[indexOfPayProvider].moduleProvider = "";
                                    modulesInputData[indexOfPayProvider].moduleProviderId = "";
                                  }

                                  setModulesInputData([...modulesInputData]);
                                }}
                                className="select-text"
                                required
                              >
                                {!(
                                  data.code === "kyc_and_onboarding" ||
                                  data.code === "aml_and_compliance" ||
                                  data.code === "payments"
                                ) ? (
                                  <>
                                    {data.code === "payments" &&
                                    data.moduleProvider !== "" ? (
                                      <option defaultValue hidden>
                                        {data.moduleProvider}
                                      </option>
                                    ) : (
                                      <option defaultValue hidden>
                                        select provider..
                                      </option>
                                    )}
                                    {data.providerDetails.map((elem, index) => {
                                      return (
                                        elem &&
                                        elem.id && 
                                        elem.isActive && (
                                          <option key={index} value={elem.id}>
                                            {elem.name}
                                          </option>
                                        )
                                      );
                                    })}
                                  </>
                                ) : (
                                  " "
                                )}

                                {data.code === "payments" &&
                                  data.moduleProvider !== "" && (
                                    <option defaultValue hidden>
                                      {data.moduleProvider}
                                    </option>
                                  )}
                              </select>
                              <label className="select-label">Provider</label>
                            </div>
                          </div>

                          <div className="form-field">
                            <div className="form-field__control">
                              <input
                                id={data.code}
                                type="text"
                                className="form-field__input"
                                placeholder=" "
                                autoComplete="off"
                                value={data.moduleSecret}
                                readOnly
                              />
                              <label
                                htmlFor={data.code}
                                className="form-field__label"
                              >
                                Module Secret
                              </label>
                              <div className="form-field__bar"></div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              );
            })}

            <div className={`addCustomer-module-btn ${btnVerify === 1 ? "" : "btn-active"}`}>
              <button
                disabled={btnVerify > 0 && true}
                onClick={openProviderSecretDialog}
              >
                Next
                <span id="addCustomer-module-loader"></span>
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default AddCustomerModules;
