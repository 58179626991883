import React from 'react';
import PropTypes from 'prop-types';

import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import subMonths from 'date-fns/subMonths';
import subYears from 'date-fns/subYears';

import 'rsuite/dist/styles/rsuite-default.css';
import './DateSelector.scss';

const { afterToday, before, combine } = DateRangePicker;
const Locale = {
	sunday: 'Sun',
	monday: 'Mon',
	tuesday: 'Tue',
	wednesday: 'Wed',
	thursday: 'Thu',
	friday: 'Fri',
	saturday: 'Sat',
	ok: 'Apply'
};
const Ranges = [
  {
    label: '7 Days',
    value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
  },
  {
  	label: '1 Month',
  	value: [startOfDay(subMonths(new Date(), 1)), endOfDay(new Date())]
  },
  {
  	label: '3 Months',
  	value: [startOfDay(subMonths(new Date(), 3)), endOfDay(new Date())]
  },
  {
  	label: '1 Year',
  	value: [startOfDay(subYears(new Date(), 1)), endOfDay(new Date())]
  }
];
let initialStartDate = new Date()
initialStartDate.setDate(initialStartDate.getDate() - 30) 

function DateSelector(props){
	let [selectedDateRangeValue, setSelectedDateRangeValue] = React.useState(props.selectedDateRange);
	return(
		<DateRangePicker
			format="DD MMM 'YY"
			cleanable={false}
			value={selectedDateRangeValue}
			ranges={Ranges}
			locale={Locale}
			disabledDate={combine(before('2020-01-01'), afterToday())}
			onChange={(item) => {
				setSelectedDateRangeValue(item)
				props.setSelectedDateRange(item)
			}}
		/>
	)
}

DateSelector.propTypes = {
	selectedDateRange: PropTypes.any,
	setSelectedDateRange: PropTypes.any
};

DateSelector.defaultProps = {
	selectedDateRange: [initialStartDate, new Date()],
	setSelectedDateRange: {}
};

export default DateSelector;
