import React from "react";
import ReactDOM from "react-dom";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, TextField } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import KeyboardArrowDownSharp from "@material-ui/icons/KeyboardArrowDownSharp";

import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration.js";
import APIEndpointList from "../../config/modules/customer_management/endpoint";
import { ifscValidation, accountNumberValidation } from "../../inputValidators";
import bankProviderList from "../../config/providers/bank/provider";

import "./CustomerConfigDetails.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
function CustomerConfigDetails(props) {
  // UnMount Dialog on Close
  const closeCustomerConfigDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("add-customer-components")
    );
  };

  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0092ff",
      },
    },
  });

  const classes = useStyles();

  const [provider, setProvider] = React.useState("");
  const [getCompanyID, setGetCompanyId] = React.useState("");
  const [companyDetailList, setCompanyDetailList] = React.useState("");

  // Api call List of onBoarded company's
  const getCompanyDetail = () => {
    APIConfig.API_Client.get(
      APIEndpointList.COMPANY_DETAIL_DATA.baseUrl +
        APIEndpointList.COMPANY_DETAIL_DATA.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setCompanyDetailList(response.data.data);
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  const [customerDetails, setCustomerDetails] = React.useState({
    accountNumberInput: "",
    ifscInput: "",
    prefixInput: "",
  });

  let [inputData, setInputData] = React.useState({
    account_number: "",
    ifsc_code: "",
    prefix_code: "",
  });

  const [inputValidation, setInputValidation] = React.useState({
    accountNumberError: "",
    ifscCodeError: "",
  });

  const handleContactDetails = (e) => {
    let { name, value } = e.target;

    if (name === "account_number") {
      setCustomerDetails({ ...customerDetails, accountNumberInput: value });

      if (accountNumberValidation(value)) {
        setInputData({ ...inputData, [name]: value });
        setInputValidation({ ...inputValidation, accountNumberError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          accountNumberError: "Invalid format",
        });
      }
    }

    if (name === "ifsc_code") {
      setCustomerDetails({ ...customerDetails, ifscInput: value });

      if (ifscValidation(value)) {
        setInputData({ ...inputData, [name]: value });
        setInputValidation({ ...inputValidation, ifscCodeError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          ifscCodeError: "Invalid format",
        });
      }
    }

    if (name === "prefix_code") {

      setInputData({ ...inputData, [name]: value });
    }
  };

  let companySearchData = companyDetailList;

  // OnSubmit company details
  const submitAccountDetails = () => {
    let companyConfigDetail = {
      company_id: getCompanyID,
      provider_code: provider.providerCode,
      ifsc: inputData.ifsc_code,
      account_number: inputData.account_number,
      designated_prefix: inputData.prefix_code,
    };
    props.customerConfigDetail(companyConfigDetail);
  };

  React.useEffect(() => {
    getCompanyDetail();
  }, []);

  const [buttonValidation, setButtonValidation] = React.useState(true);

  React.useEffect(() => {
    if (
      inputData.account_number &&
      inputData.ifsc_code &&
      getCompanyID &&
      provider.providerCode &&
      inputData.prefix_code
    ) {
      setButtonValidation(false);
    }
  }, [inputData,provider,getCompanyID]);

  return (
    <React.Fragment>
      <Dialog
        className="customer-config-dialog"
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="customer-config-title">
          <p className="customer-config-heading">Account configuration</p>
          <p className="customer-config-subtitle">
            Fill details to configuration account
          </p>
        </div>

        <div className="close-popup">
          <CloseIcon onClick={closeCustomerConfigDialog} />
        </div>

        <DialogContent>
          <div className="customer-config-container">
            <CompanySearch
              data={companySearchData}
              setGetCompanyId={setGetCompanyId}
            />

            <div className="config-provider-code">
              <label htmlFor="config-code-dropmenu">
                <span>Provider</span>
              </label>
              <BeneficiaryBankProvider setProvider={setProvider} />
            </div>

            <div className="field-input">
              <label htmlFor="va-account-number">
                <span>Account number</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.accountNumberError) && classes.root
                }`}
                id="va-account-number"
                name="account_number"
                variant="outlined"
                placeholder="Enter Account number"
                value={customerDetails.accountNumberInput}
                error={Boolean(inputValidation.accountNumberError)}
                autoComplete="off"
                inputProps={{ maxLength: 18 }}
                onChange={handleContactDetails}
                style={{ width: 315 }}
              />
            </div>

            <div className="field-input">
              <label htmlFor="ifsc-number">
                <span>IFSC</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.ifscCodeError) && classes.root
                }`}
                id="ifsc-number"
                name="ifsc_code"
                variant="outlined"
                placeholder="Enter IFSC"
                value={customerDetails.ifscInput}
                error={Boolean(inputValidation.ifscCodeError)}
                autoComplete="off"
                onChange={handleContactDetails}
                style={{ width: 315 }}
              />
            </div>

            <div className="field-input">
              <label htmlFor="prefix-number">
                <span>Designated prefix</span>
              </label>
              <TextField
                className={`${classes.root}`}
                id="prefix-number"
                name="prefix_code"
                variant="outlined"
                placeholder="Enter designated prefix"
                value={inputData.prefix_code}
                inputProps={{ maxLength: 6 }}
                autoComplete="off"
                onChange={handleContactDetails}
                style={{ width: 315 }}
              />
            </div>
          </div>

          <div className="customer-config-btn">
            <button
              disabled={buttonValidation}
              onClick={submitAccountDetails}
              className={`submit-config-details
            ${buttonValidation ? "" : "active-config-btn"}`}
            >
              Submit
              <span id="customer-config-loader"></span>
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

// Bank Provider Input Component
function BeneficiaryBankProvider(props) {
  const [TaskMenuItem, setTaskMenuItem] = React.useState("Select Action");
  return (
    <div className="config-provider-dropdown">
      <span onClick={() => ToggleActionMenu()}>
        {TaskMenuItem === "Select Action" ? (
          <span className="placeholder-text">{TaskMenuItem}</span>
        ) : (
          <span className="provider-text">{TaskMenuItem}</span>
        )}
        <KeyboardArrowDownSharp />
      </span>
      <div className="provider-bank-option">
        <div className="provider-options">
          {bankProviderList.map((data, index) => (
            <div key={index}>
              <div
                onClick={() => {
                  document
                    .querySelector(".provider-bank-option")
                    .classList.remove("active");
                  setTaskMenuItem(data.bank_name);
                  props.setProvider({ providerCode: data.bank_code });
                }}
                className="option"
              >
                {data.bank_name}
              </div>
              {index === bankProviderList.length - 1 ? (
                " "
              ) : (
                <Divider variant="middle" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function ToggleActionMenu(props) {
  document.querySelector(".provider-bank-option").classList.toggle("active");
}

// Company Search Input Component
function CompanySearch(props) {
  const [filteredData, setFilteredData] = React.useState([]);
  const [wordEntered, setWordEntered] = React.useState("");
  const [input, setInput] = React.useState("open");

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    setInput("open");
    const newFilter = props.data.filter((value) => {
      return value.commonName.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
    props.setGetCompanyId("");
  };

  const idDataHandler = (data) => {
    setWordEntered(data.commonName);
    setInput("close");
    props.setGetCompanyId(data.companyId);
  };

  return (
    <div className="search-field">
      <div className="search-input-label">Company</div>
      <div className="searchInputs">
        <input
          type="text"
          placeholder="Enter company name"
          value={wordEntered}
          className="search-input"
          onChange={handleFilter}
        />
        <div className="searchIcon">
          {filteredData.length === 0 ? (
            <SearchIcon />
          ) : (
            <CloseIcon id="clearBtn" onClick={clearInput} />
          )}
        </div>
      </div>
      {filteredData.length !== 0 && input === "open" && (
        <div className="dataResult">
          {filteredData.map((value) => {
            return (
              <div
                key={value.companyId}
                onClick={() => idDataHandler(value)}
                className="dataItem"
              >
                <p>{value.commonName}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default CustomerConfigDetails;
