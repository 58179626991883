import React from 'react';
import PropTypes from 'prop-types';

import Chart from 'react-apexcharts';

import './NewCustomersGraph.scss';

function NewCustomersGraph(props){
	let data;
	let dataText;
	if (props.data.total_accounts){
		dataText = "New Accounts"
		data = props.data.total_accounts
	}
	else if (props.data.customers){
		dataText = "New Customers"
		data = props.data.customers
	}
	let chartConfig = {
		series:[{
			name: dataText,
			data: data
		}],
        options:{
        	chart: {
				type: 'bar',
				height: 350,
				toolbar: {
					show: false
				}
			},
          	plotOptions:{
				bar:{
					horizontal: false,
					columnWidth: '15px',
					endingShape: 'rounded',
				},
			},
			grid:{
				yaxis:{
					lines:{
						show: false
					}
				},
				xaxis:{
					lines:{
						show: false
					}
				}
			},
          	dataLabels:{
          		enabled: false
	        },
	        xaxis:{
	        	categories: props.data.dates,
	        	labels: {
	        		style: {
						colors: '#838383',
						fontSize: '10px',
						fontWeight: 500
					},
					offsetY: 2,
					rotate: 0,
					rotateAlways: false
	        	},
	        	axisTicks: {
	        		show: false
	        	}
	        },
	        yaxis:{
	        	labels: {
					style: {
						colors: '#838383',
						fontSize: '10px',
						fontWeight: 500
					},
					offsetX: -8,
					offsetY: 2
				},
				axisTicks: {
					show: true,
					borderType: 'solid',
					color: '#838383',
					width: 8,
					offsetX: 0,
					offsetY: -1
				},
	        },
	        fill:{
	        	colors: ['#0092FF'],
	        	opacity: 1
	        },
	        tooltip:{
				// y:{
				// 	formatter: function (val) {
				// 		return "$ " + val + " thousands"
				// 	}
				// }
	        }
        }
    };

    return(
    	<Chart options={chartConfig.options} series={chartConfig.series} type="bar" height={250} />
    )
}

NewCustomersGraph.propTypes = {
	data: PropTypes.any
};

NewCustomersGraph.defaultProps = {
	data: {}
};

export default NewCustomersGraph;
