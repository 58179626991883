import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

import './TransactionSegregationGraph.scss';

function TransactionSegregationGraph(props){
	const less_than_1k = props.data.less_than_1000
	const between_1K_and_25K = props.data.between_1001_and_25000
	const more_than_25k = props.data.more_than_25001

	const total = less_than_1k + between_1K_and_25K + more_than_25k

	const percentage_of_more_than_25k = (more_than_25k/total) * 100;
	const percentage_of_between_1K_and_25K = (between_1K_and_25K/total) * 100;
	const percentage_of_less_than_1k = (less_than_1k/total) * 100;

	const data = [
		{ name: '<1K', value: percentage_of_less_than_1k},
		{ name: '1K - 25K', value:  percentage_of_between_1K_and_25K},
		{ name: '>25K', value: percentage_of_more_than_25k}
	]
	
	const COLORS = ['#1976D2', '#512DA8', '#7B1FA2']

	return(
		<React.Fragment>
			<div className="transaction-segregation-graph-block">
				<div className="chart-section">
					<div style={{ width: 200, height: 200 }}>
		        		<ResponsiveContainer>
							<PieChart>
								<text x={100} y={100} dy={0} textAnchor="middle">{total}</text>
								<text x={100} y={120} dy={0} textAnchor="middle">Transactions</text>
								{/*<text x={100} y={125} dy={0} textAnchor="middle">Transactions</text>*/}
						        <Pie data={data} cx={95} cy={100} innerRadius={65} outerRadius={95}
						         fill="#8884d8" paddingAngle={0} dataKey="value">
						          {
						            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
						          }
						        </Pie>
						    </PieChart>
						</ResponsiveContainer>
					</div>
				    <div className="chart-labels">
				   	 	{
				   	 		data.map((entry,i) =>
								<div className="legend" key={i}>
						    		<div className="color-dot" style={{background: COLORS[i % COLORS.length]}}></div>
						    		<div className="text">
						    			<h2>{entry.value.toFixed(2)}%</h2>
						    			<label>{entry.name}</label>
						    		</div>
						    	</div>
							)
				   	 	}
				    </div>
				</div>
			</div>
		</React.Fragment>
	);
}

TransactionSegregationGraph.propTypes = {
	data: PropTypes.any
};

TransactionSegregationGraph.defaultProps = {
	data: {less_than_1k: 0, between_1K_and_25K: 0, more_than_25k: 0}
};

export default TransactionSegregationGraph;
