import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

import RandomQuote from '../../components/RandomQuote/RandomQuote.js';

import './SuccessFailureTable.scss';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	table: {
		minWidth: 750,
	},
	icon: {
	    borderRadius: 3,
	    width: 17,
	    height: 17,
	    backgroundColor: '#FFFFFF',
	    border: '1px solid #E8E9EC',
	    'input:disabled ~ &': {
	      background: 'rgba(206,217,224,.5)',
	    },
  	},
	checkedIcon: {
		backgroundColor: '#137cbd',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 17,
			height: 17,
			backgroundImage:
			"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
			" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
			"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#106ba3',
		},
	},
}));

function SuccessFailureTable(props){
	const classes = useStyles()
	let dataRows = []
	let date
	let total
	let successful
	let failed
	let failureRate

	for (let i=0; i < props.data.length; i++){
		date = props.data[i].date
		total = props.data[i].total
		successful = props.data[i].successful
		failed = total - successful
		failureRate = ((failed * 100)/total).toFixed(2)

		dataRows.push(createData(date, total, successful, failed, failureRate))
	}

	// ReactDOM.render(<RenderTable />, document.getElementById('summaryTable'))

	function createData(date, total, successful, failed, failureRate) {
		return { date, total, successful, failed, failureRate }
	}

	return(
		<div className="success-failed-table">
			<RenderTable />
		</div>
	)

	function RenderTable(){
		return(
			<React.Fragment>
				{dataRows.length > 0?
					<div className={classes.root}>
						<TableContainer>
							<Table
								className={classes.table}
								aria-labelledby="tableTitle"
								size='medium'
								aria-label="enhanced table"
								stickyHeader={true}
							>
								<EnhancedTableHead
									classes={classes}
									rowCount={dataRows.length}
								/>
								<TableBody>
									{dataRows.map((row, index) => {
											const labelId = `enhanced-table-checkbox-${index}`;
											return (
												<TableRow
													tabIndex={-1}
													key={row.date}
												>
													<TableCell component="th" id={labelId} scope="row">
														{moment(row.date).format("Do MMM YYYY")}
													</TableCell>
													<TableCell align="center">{row.total}</TableCell>
													<TableCell align="center">{row.successful}</TableCell>
													<TableCell align="center">{row.failed}</TableCell>
													<TableCell align="center">{row.failureRate}%</TableCell>
												</TableRow>
											);
										})
									}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
					:
					<RandomQuote />
				}
			</React.Fragment>
		)
	}

}

SuccessFailureTable.propTypes = {
	classes: PropTypes.object,
	rowCount: PropTypes.number,
};

// Table Head
const headCells = [
	{ id: 'date', alignment: 'left', disablePadding: false, label: 'Date' },
	{ id: 'total', alignment: 'center', disablePadding: false, label: 'Total' },
	{ id: 'successful', alignment: 'center', disablePadding: false, label: 'Successful' },
	{ id: 'failed', alignment: 'center', disablePadding: false, label: 'Failed' },
	{ id: 'failureRate', alignment: 'center', disablePadding: false, label: 'Failure Rate' },
];
function EnhancedTableHead(props) {
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.alignment}
						padding={headCell.disablePadding ? 'none' : 'normal'}
					>
						{headCell.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	rowCount: PropTypes.number.isRequired,
}

export default SuccessFailureTable;