import React from "react";
import ReactDOM from "react-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import CloseIcon from "@material-ui/icons/Close";
import { randomUUID } from "../../services/randomUUID.js";

import { mobileNumberRegex, officeEmailRegex } from "../../inputValidators";

import "./AddCustomerDetails.scss";

function AddCustomerDetails(props) {
  const [companyInput, setCompanyInput] = React.useState({
    companyName: "",
    clientId: "",
    website: "",
    mobileNumber: "",
    legalName: "",
    clientSecrets: randomUUID(),
    officeEmail: "",
  });

  function handleCompanyInput(event) {
    const value = event.target.value;
    setCompanyInput({
      ...companyInput,
      [event.target.name]: value,
    });
  }

  let addCompanyData = {
    common_name: companyInput.companyName,
    full_name: companyInput.legalName,
    email_id: companyInput.officeEmail,
    website: companyInput.website,
    mobile_number: companyInput.mobileNumber,
  };

  let addCompanySecretData = {
    company_id: "",
    client_id: companyInput.clientId,
    client_secret: companyInput.clientSecrets,
  };

  const openModulesDialog = () => {
    if (
      companyInput.companyName !== "" &&
      companyInput.legalName !== "" &&
      companyInput.officeEmail !== "" &&
      companyInput.clientId !== "" &&
      companyInput.clientSecrets !== "" &&
      companyInput.mobileNumber !== ""
    ) {
      props.addCompanyData(addCompanyData, addCompanySecretData);
    } else {
      ReactDOM.render(
        <SnackbarMessage
          msgtype="Error"
          msg="Please fill the above required * fields"
        />,
        document.getElementById("snackbar")
      );
    }
  };

  const [buttonValidation, setButtonValidation] = React.useState(true);

  React.useEffect(() => {
    if (
      companyInput.companyName &&
      companyInput.legalName &&
      companyInput.clientId &&
      companyInput.officeEmail.match(officeEmailRegex) &&
      companyInput.mobileNumber.match(mobileNumberRegex)
    ) {
      setButtonValidation(false);
    }
  }, [companyInput]);

  return (
    <React.Fragment>
      <div className="addcustomer">
        <Dialog
          className="addcustomer-dialog"
          open={true}
          aria-labelledby="form-dialog-title"
        >
          <div className="addcustomer-title">
            <p className="addcustomer-heading">Company Details</p>
            <p className="addcustomer-subtitle">
              Fill company details to continue
              <span className="mandatory-text">
                (<span className="mandatory-sign">*</span> mandatory)
              </span>
            </p>
          </div>

          <div className="close-popup">
            <CloseIcon onClick={props.closeModal} />
          </div>

          <DialogContent>
            <div className="addcustomer-dialog-content">
              <div className="left-block">
                <div className="company-input-container">
                  <input
                    id="company-name"
                    name="companyName"
                    value={companyInput.companyName}
                    onChange={handleCompanyInput}
                    type="text"
                    required
                    spellCheck="false"
                    autoComplete="off"
                  />
                  <label className="label" htmlFor="company-name">
                    Company Name<span className="mandatory-sign">*</span>
                  </label>
                </div>

                <div className="company-input-container">
                  <input
                    id="client-id"
                    name="clientId"
                    value={companyInput.clientId}
                    onChange={handleCompanyInput}
                    type="text"
                    required
                    spellCheck="false"
                    autoComplete="off"
                  />
                  <label className="label" htmlFor="client-id">
                    Client Id<span className="mandatory-sign">*</span>
                  </label>
                </div>

                <div className="company-input-container">
                  <input
                    id="client-website"
                    name="website"
                    value={companyInput.website}
                    onChange={handleCompanyInput}
                    type="text"
                    required
                    spellCheck="false"
                    autoComplete="off"
                  />
                  <label className="label" htmlFor="client-website">
                    Website
                  </label>
                </div>

                <div className="company-input-container">
                  <input
                    id="mob-number"
                    name="mobileNumber"
                    value={companyInput.mobileNumber}
                    onChange={handleCompanyInput}
                    type="tel"
                    maxLength="10"
                    required
                    autoComplete="off"
                  />
                  <label className="label" htmlFor="mob-number">
                    Mobile Number<span className="mandatory-sign">*</span>
                  </label>
                </div>
              </div>
              <div className="right-block">
                <div className="company-input-container">
                  <input
                    id="legal-name"
                    name="legalName"
                    value={companyInput.legalName}
                    onChange={handleCompanyInput}
                    autoComplete="off"
                    spellCheck="false"
                    type="text"
                    required
                  />
                  <label className="label" htmlFor="legal-name">
                    Legal Name<span className="mandatory-sign">*</span>
                  </label>
                </div>

                <div className="company-secret-input">
                  <input
                    id="client-secret"
                    name="clientSecrets"
                    value={companyInput.clientSecrets}
                    autoComplete="off"
                    spellCheck="false"
                    type="text"
                    readOnly
                  />
                  <label className="label" htmlFor="client-secret">
                    Client secret<span className="mandatory-sign">*</span>
                  </label>
                </div>

                <div className="company-input-container">
                  <input
                    id="office-email"
                    name="officeEmail"
                    value={companyInput.officeEmail}
                    onChange={handleCompanyInput}
                    autoComplete="off"
                    spellCheck="false"
                    type="text"
                    required
                  />
                  <label className="label" htmlFor="office-email">
                    Office Email<span className="mandatory-sign">*</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="addcustomer-btn">
              <button
                disabled={buttonValidation}
                className={`submit-account-details
              ${buttonValidation ? "" : "active-detail-btn"}`}
                onClick={openModulesDialog}
              >
                Next
                <span id="addCustomer-loader"></span>
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </React.Fragment>
  );
}

export default AddCustomerDetails;
