import React, { useEffect } from 'react';
import axios from 'axios';

import { APIConfig } from '../../services/apiConfiguration.js';

import './RandomQuote.scss';

function RandomQuote(){

	let [randomQuote, setRandomQuote] = React.useState({})
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	useEffect(() => {
		APIConfig.API_Client.get(
			'https://utils.decentro.tech/quote',
			{cancelToken: source.token}
		).then(response => {
			response.data.author = "- " + response.data.author 
			setRandomQuote(response.data)
		}).catch(error => {
			console.log(error.message)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
        return () => {
        	if (source)
        		source.cancel("Quote API Cancelled");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	return(
		<div className="no-data-message">
			<p>{randomQuote.quote}</p>
			<label>{randomQuote.author}</label>
		</div>
	)

}

export default RandomQuote;