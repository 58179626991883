import React from "react";
import ReactDOM from "react-dom";

import { Loader } from "rsuite";

import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration.js";
import APIEndpointList from "../../config/modules/customer_management/endpoint";
import unMountPreviousComponent from "../../utilities/unMountPreviousComponent";

import FailureDialog from "../Shared/FailureDialog/FailureDialog";
import CustomerConfigDetails from "../CustomerConfigDetails/CustomerConfigDetails.js";
import CustomerConfigSuccessDialog from "../CustomerConfigSuccessDialog/CustomerConfigSuccessDialog.js";

import "./CustomerConfig.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

function CustomerConfig() {
  let payloadData;

  // Getting Form Details FRom Detail Component
  const customerConfigDetail = (configInputDetails) => {
    payloadData = configInputDetails;
    customerConfigAPI();
  };

  // Api call on Submit
  const customerConfigAPI = () => {
    console.log(payloadData);

    ReactDOM.render(<Loader />, document.getElementById("customer-config-loader"));

    APIConfig.API_Client.defaults.headers.post["provider_code"] = payloadData.provider_code;

    APIConfig.API_Client.post(
      APIEndpointList.MASTER_ACCOUNT_SETUP.baseUrl + APIEndpointList.MASTER_ACCOUNT_SETUP.endpoint,
      payloadData,
      { cancelToken: source.token }
    )
      .then((response) => {
        console.log(response);
        console.log("Successful");
        ReactDOM.unmountComponentAtNode(document.getElementById("customer-config-loader"));

        unMountPreviousComponent("add-customer-components");

        ReactDOM.render(
          <CustomerConfigSuccessDialog
            accountNumber={payloadData.account_number}
            data={response.data}
          />,
          document.getElementById("add-customer-components")
        );
      })
      .catch((error) => {
        console.log("Failure");

        ReactDOM.unmountComponentAtNode(document.getElementById("customer-config-loader"));

        unMountPreviousComponent("add-customer-components");

        ReactDOM.render(
          <FailureDialog header={"Account Config"} msg={error.response.data.message} />,
          document.getElementById("failure-popup")
        );
      });
  };

  // Mount Dialog Component
  const mountCustomerConfigDetail = () => {
    ReactDOM.render(
      <CustomerConfigDetails customerConfigDetail={customerConfigDetail} />,
      document.getElementById("add-customer-components")
    );
  };

  return (
    <React.Fragment>
      <div onClick={mountCustomerConfigDetail} className="action-dropdown">
        <span>Configure Customer</span>
      </div>
    </React.Fragment>
  );
}

export default CustomerConfig;
