import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import KeyboardArrowDownSharp from '@material-ui/icons/KeyboardArrowDownSharp.js';
import KeyboardArrowRightSharp from '@material-ui/icons/KeyboardArrowRightSharp.js';
import moment from 'moment';

import NewCustomersGraph from '../../components/NewCustomersGraph/NewCustomersGraph.js';
import APIUsageGraph from '../../components/APIUsageGraph/APIUsageGraph.js';
import APIHitsGraph from '../../components/APIHitsGraph/APIHitsGraph.js';
import CreditsUtilisedGraph from '../../components/CreditsUtilisedGraph/CreditsUtilisedGraph.js';
import APISummaryTable from '../../components/APISummaryTable/APISummaryTable.js';
import { APIConfig } from '../../services/apiConfiguration.js';
import SnackbarMessage from '../../components/SnackbarMessage/SnackbarMessage.js';
import DateSelector from '../../components/DateSelector/DateSelector.js';
import ResponseCodes from '../../components/ResponseCodes/ResponseCodes.js';
import RandomQuote from '../../components/RandomQuote/RandomQuote.js';

import './KYCAndOnboarding.scss';

function KYCAndOnboarding(){
	let [customerData, setCustomerData] = React.useState([{new_customers: {}}])
	let [apiData, setApiData] = React.useState([])
	let [companyList, setCompanyList] = React.useState([])
	const [screenMode, setScreenMode] = React.useState('graphs')
	const [companyId, setCompanyId] = React.useState()
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	let initialStartDate = new Date()
	initialStartDate.setDate(initialStartDate.getDate() - 30) 
	const [selectedDateRange, setSelectedDateRange] = React.useState([initialStartDate, new Date()])
	let selectedDateRangeForSummary = [initialStartDate, new Date()]

	function getCompanyList(){
		APIConfig.API_Client.get(
			APIConfig.BASE_URL+'/dashboard/admin/company_list',
			{cancelToken: source.token}
		).then(response => {
			companyList = response.data.data
			setCompanyList(response.data.data)
		}).catch(error => {
			// console.log(error.message)
		})
	}
	function getCustomerData(){
		APIConfig.API_Client.get(
			APIConfig.BASE_URL+
			'/dashboard/admin/customers?start_date='+
			moment(selectedDateRange[0].toString()).format("YYYY-MM-DD")+
			'&end_date='+
			moment(selectedDateRange[1].toString()).format("YYYY-MM-DD"),
			{cancelToken: source.token}
		).then(response => {
			customerData = response.data.data
			setCustomerData(response.data.data)
			ReactDOM.render(<RenderCustomerGraphs />, document.getElementById('customer-graphs-row'))
		}).catch(error => {
			// console.log(error.message)
		})
	}
	function getAPIData(){
		APIConfig.API_Client.get(
			APIConfig.BASE_URL+
			'/dashboard/admin?module_name=kyc-and-onboarding'+((companyId)? '&company_id='+companyId: '')+'&start_date='+
			moment(selectedDateRange[0].toString()).format("YYYY-MM-DD")+
			'&end_date='+
			moment(selectedDateRange[1].toString()).format("YYYY-MM-DD"),
			{cancelToken: source.token}
		).then(response => {
			apiData = response.data.data
			setApiData(response.data.data)
			ReactDOM.render(<RenderSummaryGraphs />, document.getElementById('summary-graphs-row'))
		}).catch(error => {
			// console.log(error.message)
		})
	}
	function sendAPISummaryData(event){
		event.preventDefault();
		ToggleDownloadMenu();
		APIConfig.API_Client.get(
			APIConfig.BASE_URL+
			'/dashboard/send_ledger?module_name=kyc-and-onboarding&start_date='+
			moment(selectedDateRangeForSummary[0].toString()).format("YYYY-MM-DD")+
			'&end_date='+
			moment(selectedDateRangeForSummary[1].toString()).format("YYYY-MM-DD"),
			{cancelToken: source.token}
		).then(response => {
			if (response.status === 200){
				ReactDOM.render(
					<SnackbarMessage msgtype="success" 
					  msg="The summary is being processed and will be dispatched over to your registered email ID shortly" 
					/>, 
					document.getElementById('snackbar')
				);
			}
		}).catch(error => {
			// console.log(error.message)
		})
	}

	useEffect(getCompanyList, [])
	useEffect(getCustomerData, [selectedDateRange]);
	useEffect(getAPIData, [selectedDateRange, companyId]);

	useEffect(() => {
        return () => {
        	if (source)
        		source.cancel("KYC And Onboarding Tab Closed");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	return(
		<React.Fragment>
			<div className="kyc">
				<h1 className="heading">
					{screenMode === 'summary'? 
						<span>
							<span className="heading-as-link" onClick={() => setScreenModeToGraphs() }>KYC & Onboarding</span>
							<span className="sub-heading"><KeyboardArrowRightSharp /> Validation Summary</span>
						</span>
						:
						"KYC & Onboarding"
					}
				</h1>
				{screenMode === 'summary'? <RenderDownloadMenu />:<RenderDateFilterAndDetailsMenu />}
			</div>
			<div id="api-summary-page"></div>
			<div className="kyc-graphs">
				<div id="customer-graphs-row"></div>
				<div className="summary" id="summary-graphs-row"></div>
			</div>
		</React.Fragment>
	)
	function RenderCustomerGraphs(){
		return(
			<div className="highlighted-row">
				<div className="graph-row">
					<div className="new-customers-graph graph">
						<div className="head">
							<h3>New Customers</h3>
						</div>
						{customerData.new_customers !== undefined
							&& customerData.new_customers.dates.length > 0?
							<div className="chart">
								<NewCustomersGraph data={customerData.new_customers} />
							</div>
							:
							<RandomQuote />
						}
					</div>
				</div>
			</div>
		)
	}
	function RenderSummaryGraphs(){
		return(
			<React.Fragment>
				<div className="head">
					<h1 className="heading-small">Summary</h1>
					<div className="action-buttons">
						<div className="select-customer-dropdown">
							<span onClick={ToggleSelectCustomerMenu}>
								{
									companyId? 
										companyList.map(function(item, i){
											if (item.id === companyId)
												return item.common_name
											return false
										})
										:
									'All Customers'
								}
								<KeyboardArrowDownSharp />
							</span>
							<div className="select-customer-dropdown-content">
								<ul>
									<li onClick={() => {
										setCompanyId(null)
									}}>
										All Customers
									</li>
									{companyList.map(function(item, i){
										return (
											<li key={i} onClick={() => {
												setCompanyId(item.id)
											}}>
												{item.common_name}
											</li>
										)
									})}
								</ul>
							</div>
						</div>
						<button className="view-details" onClick={() => {
							setScreenModeToSummary()
						}}>View Details</button>
					</div>
				</div>
				<div className="graph-row first">
					<div className="api-hits-graph graph">
						<div className="head">
							<h3>Successful API Hits</h3>
						</div>
						{apiData.kyc_trend !== undefined?
							apiData.kyc_trend.length !== 0?
								<div className="chart">
									<APIHitsGraph data={apiData.kyc_trend} />
								</div>
								:
								<RandomQuote />
							:
							<RandomQuote />
						}
					</div>
					<div className="api-usage-graph graph">
						<div className="head">
							<h3>Usage Per API</h3>
						</div>
						{apiData.usage_per_api !== undefined?
							JSON.stringify(apiData.usage_per_api) === '{}'?
								<RandomQuote />
								:
								<div className="chart">
									<APIUsageGraph data={apiData.usage_per_api} />
								</div>
							:
							<RandomQuote />
						}
					</div>
				</div>
				<div className="graph-row">
					<div className="credits-utilised-graph graph">
						<div className="head">
							<h3>Credits Utilised</h3>
						</div>
						{apiData.credits_breakdown !== undefined?
							apiData.credits_breakdown.usable_credits === 0 && apiData.credits_breakdown.used_credits === 0?
								<RandomQuote />
								:
								<div className="chart">
									<CreditsUtilisedGraph data={apiData.credits_breakdown} />
								</div>
							:
							<RandomQuote />
						}
					</div>
					<div className="failure-cause-graph graph">
						<div className="head">
							<h3>API Failure Causes</h3>
						</div>
						{apiData.failure_causes !== undefined?
							JSON.stringify(apiData.failure_causes) === '{}'?
								<RandomQuote />
								:
								<div className="chart">
									<APIUsageGraph data={apiData.failure_causes} />
								</div>
							:
							<RandomQuote />
						}
					</div>
				</div>
				<div className="graph-row negativeMargin">
					<ResponseCodes />
				</div>
			</React.Fragment>
		)
	}
	function RenderAPISummary(){
		return(
			<APISummaryTable 
				pageType="kyc-and-onboarding"
				companyId={companyId}
				selectedDates={selectedDateRange}
				backToGraphScreen={() => {
					setScreenModeToGraphs()
			}} />
		)
	}
	function RenderDownloadMenu(){
		return(
			<div className="download-dropdown">
				<span onClick={ToggleDownloadMenu}>Send Summary <KeyboardArrowDownSharp /></span>
				<div className="download-dropdown-content">
					<form onSubmit={sendAPISummaryData}>
						<DateSelector 
							selectedDateRange={selectedDateRangeForSummary} 
							setSelectedDateRange={() => {
								//
							}}
						/>
						{/*<div className="select-file-type">
							<label className="switch-text pdf active">PDF</label>
							<label className="switch">
								<input type="checkbox" onChange={(e) => {
									if(e.currentTarget.checked){
										downloadFileType = 'csv';
										document.querySelector('.pdf').classList.remove('active');
									}
									else if(!e.currentTarget.checked){
										downloadFileType = 'pdf';
										document.querySelector('.csv').classList.remove('active');
									}
									document.querySelector('.'+downloadFileType).classList.add('active');
								}} />
								<span className="slider round"></span>
							</label>
							<label className="switch-text csv">CSV</label>
						</div>*/}
						<button className="download-button" type="submit">Send</button>
					</form>
				</div>
			</div>
		)
	}
	function RenderDateFilterAndDetailsMenu(){
		return(
			<div className="head-right-block">
				<DateSelector selectedDateRange={selectedDateRange} setSelectedDateRange={setSelectedDateRange} />
			</div>
		)
	}
	function setScreenModeToGraphs(){
		setScreenMode('graphs')
		ReactDOM.unmountComponentAtNode(document.getElementById('api-summary-page'))
		ReactDOM.render(<RenderCustomerGraphs />, document.getElementById('customer-graphs-row'))
		ReactDOM.render(<RenderSummaryGraphs />, document.getElementById('summary-graphs-row'))
	}
	function setScreenModeToSummary(){
		setScreenMode('summary')
		ReactDOM.unmountComponentAtNode(document.getElementById('customer-graphs-row'))
		ReactDOM.unmountComponentAtNode(document.getElementById('summary-graphs-row'))
		ReactDOM.render(<RenderAPISummary />, document.getElementById('api-summary-page'))
	}
}

function ToggleDownloadMenu(){
	document.querySelector('.download-dropdown-content').classList.toggle('active');
}

function ToggleSelectCustomerMenu(){
	document.querySelector('.select-customer-dropdown-content').classList.toggle('active');
}

export default KYCAndOnboarding;