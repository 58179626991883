import React from 'react';
// import { NavLink, useHistory } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { clearAuthTokens } from 'axios-jwt';

import KeyboardArrowDownSharp from '@material-ui/icons/KeyboardArrowDownSharp.js';

import './Header.scss';

function Header(){
	let history = useHistory();
	let user = {}
	if (JSON.parse(localStorage.getItem("user")))
		user = JSON.parse(localStorage.getItem("user"))
	return(
		<div className="header">
			<div className="left-side">
				<img src="/images/logo.svg" alt="logo" />
			</div>
			<div className="right-side">
				{/* <div className="notification">
					<NavLink to="notifications">
						<img src="/images/notification.svg" alt="notification" />
						<label>9</label>
					</NavLink>
				</div>
				<hr /> */}
				<div className="user">
					<div className="dropdown" tabIndex="0" onBlur={CloseDropDown}>
						<span onClick={ToggleMenu}>{user.name? user.name : 'User'} <KeyboardArrowDownSharp /></span>
						<div className="dropdown-content">
							<ul>
								{/*<li>Profile</li>
								<li>Terms & Conditions</li>
								<li>Privacy Policy</li>
								<li>Help & Support</li>*/}
								<li>Logged in with {user.email? user.email : 'your email'}</li>
								<li className="logout" onClick={() => {
									clearAuthTokens();
									history.push("/login");
								}}>Logout</li>
							</ul>
						</div>
					</div>
					<div className="avatar">{user.name? user.name[0] : 'User'}</div>
				</div>
			</div>
		</div>
	)
}

function ToggleMenu(){
	document.querySelector('.dropdown-content').classList.toggle('active');
}

function CloseDropDown(){
	document.querySelector('.dropdown-content').classList.remove('active');
}

export default Header;
