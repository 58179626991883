const ifscRegex = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
const accountNumberRegex = /^\d{9,18}$/;
const mobileNumberRegex = /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[789]\d{9}$/;
const officeEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const ifscValidation = (value) => {
  if (value.match(ifscRegex)) {
    return true;
  }
  return false;
};

const accountNumberValidation = (value) => {
  if (value.match(accountNumberRegex)) {
    return true;
  }
  return false;
};

export {
  ifscValidation,
  accountNumberValidation,
  mobileNumberRegex,
  officeEmailRegex,
};
