import React from "react";
import ReactDOM from "react-dom";
import { randomUUID } from "../../services/randomUUID.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";

import "./AddCustomerProviderSecret.scss";

function AddCustomerProviderSecret(props) {
  const closeModal = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById("add-customer-components"));
  };


  const providerInitialData = props.providerData
    .map((item, idx) => {
      return {
        ...item,
        providerSecret: randomUUID(),
      };
    })
    .filter(
      (item, index, self) =>
        self.findIndex(
          (t) =>
            t.moduleProviderId === item.moduleProviderId &&
            t.moduleProvider === item.moduleProvider
        ) === index
    );


  const openApiMeterDialog = () => {
    //! Obj for provider  payload
    const providerSecretObj = providerInitialData
      .filter((item) => {
        if (!!item.providerSecret) return item;
        return false;
      })
      .map((el, index) => {
        return {
          id: +el.moduleProviderId,
          key: el.providerSecret,
        };
      });

      props.addProviderSecret(providerSecretObj,providerInitialData);

  };

  return (
    <div>
      <Dialog
        className="provider-secret-dialog"
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="provider-secret-title">
          <p className="provider-secret-head">Provider Secret</p>
          <p className={"provider-secret-subtitle"}>
            provider secret detail
          </p>
        </div>

        <div className="close-popup">
          <CloseIcon onClick={closeModal} />
        </div>

        <DialogContent className="provider-secret-dialog">

          {providerInitialData.map((data, index) => {
            return (
              <div
                key={data.moduleProviderId}
                className="provider-secret-details"
              >
                <p>{data.moduleProvider}</p>
                <div className="form-field">
                  <div className="form-field__control">
                    <input
                      type="text"
                      className="form-field__input"
                      placeholder=" "
                      autoComplete="off"
                      value={data.providerSecret}
                      readOnly
                    />
                    <label className="form-field__label">Secret</label>
                    <div className="form-field__bar"></div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="provider-secret-btn">
            <button onClick={openApiMeterDialog}>
              Next
              <span id="provider-customer-loader"></span>
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddCustomerProviderSecret;
