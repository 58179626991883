import React from "react";
import ReactDOM from "react-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import "./AddCustomerApiMeter.scss";

function AddCustomerApiMeter(props) {
  const apiMeterInitialData = props.apiMeterData.map((item, idx) => {
    return {
      ...item,
      maxUsableCredits: "",
      renewalThresholdPercentage: "",
      planType: "PREPAID_CREDITS",
      planPricingType: "STARTER",
      taxRate: "",
    };
  });

  const [apiMeterInputData, setApiMeterInputData] =
    React.useState(apiMeterInitialData);
  const [btnVerify, setBtnVerify] = React.useState(1);

  React.useEffect(() => {
    for (let i = 0; i < apiMeterInputData.length; i++) {
      if (
        apiMeterInputData[i].renewalThresholdPercentage !== "" &&
        apiMeterInputData[i].planPricingType !== "" &&
        apiMeterInputData[i].taxRate !== "" &&
        apiMeterInputData[i].maxUsableCredits !== "" &&
        apiMeterInputData[i].planType !== ""
      ) {
        setBtnVerify(0);
      } else {
        setBtnVerify(1);
      }
    }
  }, [apiMeterInputData]);

  const submitApiMeterDetails = () => {
    const apiMeterObj = apiMeterInputData.map((el, index) => {
      return {
        module_code: el.moduleCode,
        max_usable_credits: +el.maxUsableCredits,
        renewal_threshold_percentage: +el.renewalThresholdPercentage,
        plan_type: el.planType,
        plan_pricing_type: el.planPricingType,
        tax_rate: +el.taxRate,
      };
    });

    props.addApiMeterDetails(apiMeterObj);
  };

  const closeModal = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("add-customer-components")
    );
  };

  return (
    <div>
      <Dialog
        className="meter-details-dialog"
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="meter-details-title">
          <p className="meter-details-head">API Meter Details</p>
          <p className="meter-details-subtitle">Fill API meter details below <span className="mandatory-text">(<span className="mandatory-sign">*</span> mandatory)</span> </p>
        </div>

        <div className="close-popup">
          <CloseIcon onClick={closeModal} />
        </div>

        <DialogContent>
          <div className="meter-details-modules">
            {apiMeterInputData.map((data, indx) => {
              return (
                <div key={indx} className="meter-details-container">
                  <div className="meter-details-heading">
                    <p>{data.moduleName}</p>
                  </div>

                  <div className={`meter-details-box`}>
                    <div className="input-container">

                      <div className="meter-input-container">
                        <input
                          id={`threshold-${indx}`}
                          type="number"
                          step=".01"
                          autoComplete="off"
                          onChange={(e) => {
                            if (
                              (parseInt(e.target.value) > 0 &&
                                parseInt(e.target.value) <= 100) ||
                              e.target.value === ""
                            ) {
                              data.renewalThresholdPercentage = e.target.value;
                              setApiMeterInputData([...apiMeterInputData]);
                            } else {
                              e.preventDefault();
                              e.target.value = "";
                            }
                          }}
                          value={data.renewalThresholdPercentage}
                          required
                          spellCheck="false"
                        />
                        <label className="label" htmlFor={`threshold-${indx}`}>
                          Threshold(%) <span className="mandatory-sign">*</span> 
                        </label>
                      </div>

                      <div className="meter-input-container">
                        <input
                          id={`credits-${indx}`}
                          onChange={(e) => {
                            data.maxUsableCredits = e.target.value;
                            setApiMeterInputData([...apiMeterInputData]);
                          }}
                          value={data.maxUsableCredits}
                          type="text"
                          required
                          spellCheck="false"
                          autoComplete="off"
                        />
                        <label className="label" htmlFor={`credits-${indx}`}>
                          Credits<span className="mandatory-sign">*</span> 
                        </label>
                      </div>


                      <div className="meter-input-container">
                        <input
                          id={`tax-${indx}`}
                          onChange={(e) => {
                            data.taxRate = e.target.value;
                            setApiMeterInputData([...apiMeterInputData]);
                          }}
                          value={data.taxRate}
                          type="text"
                          required
                          spellCheck="false"
                          autoComplete="off"
                        />
                        <label className="label" htmlFor={`tax-${indx}`}>
                          Tax<span className="mandatory-sign">*</span> 
                        </label>
                      </div>


                    </div>

                    {/*Plan dropdown */}      
                    <div className="dropdown-container">
                      <div className="form-field_select">
                        <div className="api-meter-select">
                          <select
                            onChange={({ target }) => {
                              data.planType = target.value;
                              setApiMeterInputData([...apiMeterInputData]);
                            }}
                            className="api-meter-select-text"
                            required
                          >
                            {/* <option defaultValue hidden>
                              Select Plan..
                            </option> */}
                            {props.planOptions.map((elem, index) => {
                              return (
                                <option key={index} value={elem} defaultValue>
                                    {elem}
                                </option>
                              );
                            })}
                          </select>
                          <label className="api-meter-select-label">Plan<span className="mandatory-sign">*</span> </label>
                        </div>
                      </div>

                      {/*Pricing dropdown */}
                      <div className="form-field_select">
                        <div className="api-meter-select">
                          <select
                            onChange={({ target }) => {
                              data.planPricingType = target.value;
                              setApiMeterInputData([...apiMeterInputData]);
                            }}
                            className="api-meter-select-text"
                            required
                          >
                            {/* <option defaultValue hidden>
                             Select Pricing..
                            </option> */}
                            {props.planPricing.map((elem, index) => {
                              return (
                                <option key={index} value={elem} defaultValue>
                                    {elem}
                                </option>
                              );
                            })}
                          </select>
                          <label className="api-meter-select-label">Pricing<span className="mandatory-sign">*</span> </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            className={`meter-details-btn ${
              btnVerify === 1 ? "" : "btn-active"
            }`}
          >
            <button
              onClick={submitApiMeterDetails}
              disabled={btnVerify > 0 && true}
            >
              Next
              <span id="apiMeter-customer-loader"></span>
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddCustomerApiMeter;
