const bankProviderList =[
    {
      "bank_name": "ICICI BANK",
      "bank_code": "icic"
    },
    {
      "bank_name": "YES BANK",
      "bank_code": "yesb"
    },
  ];
  
  export default bankProviderList;