import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import KeyboardArrowDownSharp from '@material-ui/icons/KeyboardArrowDownSharp.js';
import KeyboardArrowRightSharp from '@material-ui/icons/KeyboardArrowRightSharp.js';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';

import NewCustomersGraph from '../../components/NewCustomersGraph/NewCustomersGraph.js';
// import AccountBalanceGraph from '../../components/AccountBalanceGraph/AccountBalanceGraph.js';
import APIUsageGraph from '../../components/APIUsageGraph/APIUsageGraph.js';
import APIHitsGraph from '../../components/APIHitsGraph/APIHitsGraph.js';
import CreditsUtilisedGraph from '../../components/CreditsUtilisedGraph/CreditsUtilisedGraph.js';
import TransactionSegregationGraph from '../../components/TransactionSegregationGraph/TransactionSegregationGraph.js';
import MoneyTransferLogsTable from '../../components/MoneyTransferLogsTable/MoneyTransferLogsTable.js';
import APISummaryTable from '../../components/APISummaryTable/APISummaryTable.js';
import { APIConfig } from '../../services/apiConfiguration.js';
import SnackbarMessage from '../../components/SnackbarMessage/SnackbarMessage.js';
import DateSelector from '../../components/DateSelector/DateSelector.js';
import SuccessFailureTable from '../../components/SuccessFailureTable/SuccessFailureTable.js';
import RandomQuote from '../../components/RandomQuote/RandomQuote.js';

import './VirtualAccounts.scss';

function VirtualAccounts(){
	let [accountData, setAccountData] = React.useState([{new_accounts: {}, account_balance: {}}])
	let [apiData, setApiData] = React.useState([])
	let [transactionData, setTransactionData] = React.useState([])
	let [companyBalanceSummary, setCompanyBalanceSummary] = React.useState([])
	let [companyList, setCompanyList] = React.useState([])
	let [providerList, setProviderList] = React.useState([])
	const [screenMode, setScreenMode] = React.useState('graphs')
	const [accountType, setAccountType] = React.useState(2)
	const [providerId, setProviderId] = React.useState()
	const [companyId, setCompanyId] = React.useState()
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	let initialStartDate = new Date()
	initialStartDate.setDate(initialStartDate.getDate() - 30)
	const [selectedDateRange, setSelectedDateRange] = React.useState([initialStartDate, new Date()])
	let selectedDateRangeForLogs = [initialStartDate, new Date()]

	function getCompanyList(){
		APIConfig.API_Client.get(
			APIConfig.BASE_URL+'/dashboard/admin/company_list', 
			{cancelToken: source.token}
		).then(response => {
			companyList = response.data.data
			setCompanyList(response.data.data)
		}).catch(error => {
			// console.log(error.message)
		})
	}
	function getProviderList(){
		APIConfig.API_Client.get(
			APIConfig.BASE_URL+'/dashboard/admin/provider', 
			{cancelToken: source.token}
	    ).then(response => {
			providerList = response.data.data
			setProviderList(response.data.data)
		}).catch(error => {
			// console.log(error.message)
		})
	}
	function getAccountData(){
		APIConfig.API_Client.get(
			APIConfig.BASE_URL+
			'/dashboard/admin/accounts?account_type='+accountType+((providerId)? '&provider_id='+providerId: '')+'&start_date='+
			moment(selectedDateRange[0].toString()).format("YYYY-MM-DD")+
			'&end_date='+
			moment(selectedDateRange[1].toString()).format("YYYY-MM-DD"), 
			{cancelToken: source.token}
	    ).then(response => {
			accountData = response.data.data
			setAccountData(response.data.data)
			ReactDOM.render(<RenderAccountGraphs />, document.getElementById('account-graphs-row'))
		}).catch(error => {
			// console.log(error.message)
		})
	}
	function getAPIData(){
		APIConfig.API_Client.get(
			APIConfig.BASE_URL+
			'/dashboard/admin?module_name=accounts'+((companyId)? '&company_id='+companyId: '')+'&start_date='+
			moment(selectedDateRange[0].toString()).format("YYYY-MM-DD")+
			'&end_date='+
			moment(selectedDateRange[1].toString()).format("YYYY-MM-DD"), 
			{cancelToken: source.token}
	    ).then(response => {
			apiData = response.data.data
			setApiData(response.data.data)
		}).then(() => {
			getCompanyBalanceSummary()
		}).catch(error => {
			// console.log(error.message)
		})
	}
	function getCompanyBalanceSummary(){
		APIConfig.API_Client.get(
			APIConfig.BASE_URL+
			'/dashboard/admin/company_balance_summary?'+((companyId)? 'company_id='+companyId: '')+'&start_date='+
			moment(selectedDateRange[0].toString()).format("YYYY-MM-DD")+
			'&end_date='+
			moment(selectedDateRange[1].toString()).format("YYYY-MM-DD"), 
			{cancelToken: source.token}
	    ).then(response => {
			companyBalanceSummary = response.data.data
			setCompanyBalanceSummary(response.data.data)
			ReactDOM.render(<RenderSummaryGraphs />, document.getElementById('summary-graphs-row'))
		}).catch(error => {
			// console.log(error.message)
		})
	}
	function getTransactionData(){
		APIConfig.API_Client.get(
			APIConfig.BASE_URL+
			'/dashboard/admin/transaction_statistics?'+((companyId)? '&company_id='+companyId: '')+'&start_date='+
			moment(selectedDateRange[0].toString()).format("YYYY-MM-DD")+
			'&end_date='+
			moment(selectedDateRange[1].toString()).format("YYYY-MM-DD"), 
			{cancelToken: source.token}
	    ).then(response => {
			transactionData = response.data.data
			setTransactionData(response.data.data)
			ReactDOM.render(<RenderTransactionGraphs />, document.getElementById('transaction-graphs-row'))
		}).catch(error => {
			// console.log(error.message)
		})
	}
	function sendMoneyTransferLogs(event){
		event.preventDefault();
		ToggleDownloadMenu();
		APIConfig.API_Client.get(
			APIConfig.BASE_URL+
			'/dashboard/send_ledger?module_name=accounts&start_date='+
			moment(selectedDateRangeForLogs[0].toString()).format("YYYY-MM-DD")+
			'&end_date='+
			moment(selectedDateRangeForLogs[1].toString()).format("YYYY-MM-DD"), 
			{cancelToken: source.token}
	    ).then(response => {
			if (response.status === 200){
				ReactDOM.render(
					<SnackbarMessage msgtype="success" 
					  msg="The logs are being processed and will be dispatched over to your registered email ID shortly" 
					/>, 
					document.getElementById('snackbar')
				);
			}
		}).catch(error => {
			// console.log(error.message)
		})
	}

	useEffect(getCompanyList, [])
	useEffect(getProviderList, [])
	useEffect(getAccountData, [selectedDateRange, accountType, providerId])
	useEffect(getAPIData, [selectedDateRange, companyId])
	useEffect(getTransactionData, [selectedDateRange, companyId])

	useEffect(() => {
        return () => {
        	if (source)
        		source.cancel("Virtual Account Tab Closed");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	return(
		<React.Fragment>
			<div className="virtual-accounts">
				<h1 className="heading">
					{screenMode === 'logs' || screenMode === 'summary'? 
						<span>
							<span className="heading-as-link" onClick={() => setScreenModeToGraphs() }>Virtual Accounts</span>
							<span className="sub-heading"><KeyboardArrowRightSharp /> {screenMode==='logs'? 'Money Transfer Logs' : 'API Summary'}</span>
						</span>
						:
						"Virtual Accounts"
					}
				</h1>
				{screenMode === 'logs' || screenMode === 'summary'? <RenderDownloadMenu />:<RenderDateFilterAndDetailsMenu />}
			</div>
			<div id="logs-page"></div>
			<div id="api-summary-page"></div>
			<div className="virtual-account-graphs">
				<div id="account-graphs-row"></div>
				<div className="summary" id="summary-graphs-row"></div>
				<div className="transactions" id="transaction-graphs-row"></div>
			</div>
		</React.Fragment>
	)
	function RenderAccountGraphs(){
		return(
			<div className="highlighted-row">
				<div className="graph-options">
					<button className={accountType === 2? 'active':''} onClick={() => {
						setAccountType(2)
					}}>
						Virtual
					</button>
					<button className={accountType === 3? 'active':''} onClick={() => {
						setAccountType(3)
					}}>
						Savings
					</button>
					<button className={accountType === 4? 'active':''} onClick={() => {
						setAccountType(4)
					}}>
						Current
					</button>
				</div>
				<div className="graph-row">
					<div className="new-accounts-graph graph">
						<div className="head">
							<h3>New Accounts</h3>
						</div>
						{accountData.new_accounts !== undefined 
							&& accountData.new_accounts.dates.length > 0?
							<div className="chart">
								<NewCustomersGraph data={accountData.new_accounts} />
							</div>
							:
							<RandomQuote />
						}
					</div>
					{/*<div className="account-balance-graph graph">
						<div className="head">
							<h3>Account Balance</h3>
						</div>
						{accountData.account_balance !== undefined
							&& accountData.account_balance.dates.length > 0?
							<div className="chart">
								<AccountBalanceGraph data={accountData.account_balance} />
							</div>
							:
							<RandomQuote />
						}
					</div>*/}
				</div>
			</div>
		)
	}
	function RenderSummaryGraphs(){
		return(
			<React.Fragment>
				<div className="head">
					<h1 className="heading-small">Summary</h1>
					<div className="action-buttons">
						<div className="select-customer-dropdown">
							<span onClick={ToggleSelectCustomerMenu}>
								{
									companyId? 
										companyList.map(function(item, i){
											if (item.id === companyId)
												return item.common_name
											return false
										})
										:
									'All Customers'
								}
								<KeyboardArrowDownSharp />
							</span>
							<div className="select-customer-dropdown-content">
								<ul>
									<li onClick={() => {
										setCompanyId(undefined)
									}}>
										All Customers
									</li>
									{companyList.map(function(item, i){
										return (
											<li key={i} onClick={() => {
												setCompanyId(item.id)
											}}>
												{item.common_name}
											</li>
										)
									})}
								</ul>
							</div>
						</div>
						<button className="view-details" onClick={() => {
							setScreenModeToAPISummaryOrLogs('summary')
						}}>View Details</button>
					</div>
				</div>
				<div className="summary-row">
					{companyId === undefined?
						<div className="for-all">
							<div className="left">
								<div className="summary-content">
									<label>Total Commission Collected</label>
									<h3>&#8377; {companyBalanceSummary.total_commission_collected? 
													companyBalanceSummary.total_commission_collected.toFixed(2)
													:
													0
												}</h3>
								</div>
								<div className="summary-content">
									<label>Total Credits Used</label>
									<h3>&#8377; {companyBalanceSummary.total_credits_used?
											companyBalanceSummary.total_credits_used.toFixed(2)
											:
											0
										}</h3>
								</div>
								{/*<button>View Details</button>*/}
							</div>
							<div className="right">
								<div className="summary-content">
									<label>Virtual Balance</label>
									<h3>&#8377; {companyBalanceSummary.total_virtual_balance?
											companyBalanceSummary.total_virtual_balance.toFixed(2)
											:
											0
										}</h3>
									<ErrorIcon />
								</div>
							</div>
						</div>
						:
						<div className="for-company">
							<div className="left">
								<div className="summary-content">
									<label>MAIN</label>
									<div className="summary-amount">
										<div className="amount">
											<span>Credit</span>
											<h3>&#8377; {companyBalanceSummary.main? 
														companyBalanceSummary.main.credit.toFixed(2)
														:
														0
													}</h3>
										</div>
										<hr />
										<div className="amount">
											<span>Debit</span>
											<h3>&#8377; {companyBalanceSummary.main? 
														companyBalanceSummary.main.debit.toFixed(2)
														:
														0
													}</h3>
										</div>
									</div>
								</div>
								<div className="summary-content">
									<label>CREDIT</label>
									<div className="summary-amount">
										<div className="amount">
											<span>Used</span>
											<h3>&#8377; {companyBalanceSummary.credit? 
														companyBalanceSummary.credit.used_credits.toFixed(2)
														:
														0
													}</h3>
										</div>
										<hr />
										<div className="amount">
											<span>Balance</span>
											<h3>&#8377; {companyBalanceSummary.credit? 
														(companyBalanceSummary.credit.usable_credits - companyBalanceSummary.credit.used_credits).toFixed(2)
														:
														0
													}</h3>
										</div>
									</div>
								</div>
								<div className="summary-content">
									<label>COMMISSION</label>
									<div className="summary-amount">
										<div className="amount">
											<span>Debit</span>
											<h3>&#8377; {companyBalanceSummary.commission? 
														companyBalanceSummary.commission.toFixed(2)
														:
														0
													}</h3>
										</div>
									</div>
								</div>
								<button>View Details</button>
							</div>
							{/*<div className="right">
								<div className="summary-content">
									<label>Virtual Balance</label>
									<h3>&#8377; {companyBalanceSummary.total_virtual_balance?
											companyBalanceSummary.total_virtual_balance.toFixed(2)
											:
											0
										}</h3>
									<ErrorIcon />
								</div>
							</div>*/}
						</div>
					}
				</div>
				<div className="graph-row">
					<div className="api-hits-graph graph">
						<div className="head">
							<h3>Successful API Hits</h3>
						</div>
						{apiData.kyc_trend !== undefined?
							apiData.kyc_trend.length !== 0?
								<div className="chart">
									<APIHitsGraph data={apiData.kyc_trend} />
								</div>
								:
								<RandomQuote />
							:
							<RandomQuote />
						}
					</div>
					<div className="api-hits-table table">
						<div className="head">
							<h3>API Hits</h3>
						</div>
						{apiData.kyc_trend !== undefined?
							apiData.kyc_trend.length !== 0?
								<SuccessFailureTable data={apiData.kyc_trend} />
								:
								<RandomQuote />
							:
							<RandomQuote />
						}
					</div>
				</div>
				<div className="graph-row">
					<div className="api-usage-graph graph">
						<div className="head">
							<h3>Usage Per API</h3>
						</div>
						{apiData.usage_per_api !== undefined?
							JSON.stringify(apiData.usage_per_api) === '{}' || apiData.usage_per_api.null === 0?
								<RandomQuote />
								:
								<div className="chart">
									<APIUsageGraph data={apiData.usage_per_api} />
								</div>
							:
							<RandomQuote />
						}
					</div>
					<div className="credits-utilised-graph graph">
						<div className="head">
							<h3>Credits Utilised</h3>
						</div>
						{apiData.credits_breakdown !== undefined?
							apiData.credits_breakdown.usable_credits === 0 && apiData.credits_breakdown.used_credits === 0?
								<RandomQuote />
								:
								<div className="chart">
									<CreditsUtilisedGraph data={apiData.credits_breakdown} />
								</div>
							:
							<RandomQuote />
						}
					</div>
				</div>
			</React.Fragment>
		)
	}
	function RenderTransactionGraphs(){
		return(
			<React.Fragment>
				<div className="head">
					<h1 className="heading-small">Transactions</h1>
					<button className="view-details" onClick={() => {
						setScreenModeToAPISummaryOrLogs('logs')
					}}>View Details</button>
				</div>
				<div className="graph-row">
					<div className="transaction-success-graph graph">
						<div className="head">
							<h3>Successful Transactions</h3>
						</div>
						{transactionData.transaction_trend !== undefined?
							transactionData.transaction_trend.length !== 0?
								<div className="chart">
									<APIHitsGraph data={transactionData.transaction_trend} />
								</div>
								:
								<RandomQuote />
							:
							<RandomQuote />
						}
					</div>
					<div className="transaction-table table">
						<div className="head">
							<h3>Transactions</h3>
						</div>
						{transactionData.transaction_trend !== undefined?
							transactionData.transaction_trend.length !== 0?
								<SuccessFailureTable data={transactionData.transaction_trend} />
								:
								<RandomQuote />
							:
							<RandomQuote />
						}
					</div>
				</div>
				<div className="graph-row">
					<div className="transaction-type-graph graph">
						<div className="head">
							<h3>Usage Per Type</h3>
						</div>
						{transactionData.usage_per_type !== undefined?
							JSON.stringify(transactionData.usage_per_type) === '{}'?
								<RandomQuote />
								:
								<div className="chart">
									<APIUsageGraph data={transactionData.usage_per_type} />
								</div>
							:
							<RandomQuote />
						}
					</div>
					<div className="transaction-segregation-graph graph">
						<div className="head">
							<h3>Amount Based Segregation</h3>
						</div>
						{transactionData.transaction_amount_segregation !== undefined?
							JSON.stringify(transactionData.transaction_amount_segregation) === '{}'?
								<RandomQuote />
								:
								<div className="chart">
									<TransactionSegregationGraph data={transactionData.transaction_amount_segregation} />
								</div>
							:
							<RandomQuote />
						}
					</div>
				</div>
			</React.Fragment>
		)
	}
	function RenderAPISummary(){
		return(
			<APISummaryTable 
				pageType="accounts"
				companyId={companyId}
				selectedDates={selectedDateRange}
				backToGraphScreen={() => {
					setScreenModeToGraphs()
			}} />
		)
	}
	function RenderMoneyTransferLogs(){
		return(
			<MoneyTransferLogsTable 
				pageType="accounts"
				companyId={companyId}
				selectedDates={selectedDateRange}
				backToGraphScreen={() => {
					setScreenModeToGraphs()
			}} />
		)
	}
	function RenderDownloadMenu(){
		return(
			<div className="download-dropdown">
				<span onClick={ToggleDownloadMenu}>Send Logs <KeyboardArrowDownSharp /></span>
				<div className="download-dropdown-content">
					<form onSubmit={sendMoneyTransferLogs}>
						<DateSelector 
							selectedDateRange={selectedDateRangeForLogs} 
							setSelectedDateRange={(event) => {
								selectedDateRangeForLogs[0] = event[0]
							}}
						/>
						{/*<div className="select-file-type">
							<label className="switch-text pdf active">PDF</label>
							<label className="switch">
								<input type="checkbox" onChange={(e) => {
									if(e.currentTarget.checked){
										downloadFileType = 'csv';
										document.querySelector('.pdf').classList.remove('active');
									}
									else if(!e.currentTarget.checked){
										downloadFileType = 'pdf';
										document.querySelector('.csv').classList.remove('active');
									}
									document.querySelector('.'+downloadFileType).classList.add('active');
								}} />
								<span className="slider round"></span>
							</label>
							<label className="switch-text csv">CSV</label>
						</div>*/}
						<button className="download-button" type="submit">Send</button>
					</form>
				</div>
			</div>
		)
	}
	function RenderDateFilterAndDetailsMenu(){
		return(
			<div className="head-right-block">
				<div className="select-provider-dropdown">
					<span onClick={ToggleSelectProviderMenu}>
						{
							providerId? 
								providerList.map(function(item, i){
									if (item.id === providerId)
										return item.display_name
									return false
								})
								:
							'All Providers'
						}
						<KeyboardArrowDownSharp />
						</span>
					<div className="select-provider-dropdown-content">
						<ul>
							<li onClick={() => {
								setProviderId(undefined)
							}}>
								All Providers
							</li>
							{providerList.map(function(item, i){
								return (
									<li key={i} onClick={() => {
										setProviderId(item.id)
									}}>
										{item.display_name}
									</li>
								)
							})}
						</ul>
					</div>
				</div>
				<DateSelector selectedDateRange={selectedDateRange} setSelectedDateRange={setSelectedDateRange} />
			</div>
		)
	}
	function setScreenModeToGraphs(){
		setScreenMode('graphs')
		ReactDOM.unmountComponentAtNode(document.getElementById('api-summary-page'))
		ReactDOM.unmountComponentAtNode(document.getElementById('logs-page'))
		ReactDOM.render(<RenderAccountGraphs />, document.getElementById('account-graphs-row'))
		ReactDOM.render(<RenderSummaryGraphs />, document.getElementById('summary-graphs-row'))
		ReactDOM.render(<RenderTransactionGraphs />, document.getElementById('transaction-graphs-row'))
	}
	function setScreenModeToAPISummaryOrLogs(pageType){
		setScreenMode(pageType)
		ReactDOM.unmountComponentAtNode(document.getElementById('account-graphs-row'))
		ReactDOM.unmountComponentAtNode(document.getElementById('summary-graphs-row'))
		ReactDOM.unmountComponentAtNode(document.getElementById('transaction-graphs-row'))
		if (pageType === 'summary')
			ReactDOM.render(<RenderAPISummary />, document.getElementById('api-summary-page'))
		else
			ReactDOM.render(<RenderMoneyTransferLogs />, document.getElementById('logs-page'))
	}
}

function ToggleDownloadMenu(){
	document.querySelector('.download-dropdown-content').classList.toggle('active')
}

function ToggleSelectCustomerMenu(){
	document.querySelector('.select-customer-dropdown-content').classList.toggle('active')
}

function ToggleSelectProviderMenu(){
	document.querySelector('.select-provider-dropdown-content').classList.toggle('active')
}

export default VirtualAccounts;